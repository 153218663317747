import React from "react";

const Terminal = () => {
  return (
    <div data-aos="zoom-in" className="terminal">
      <div className="terminal-header">
        <div className="terminal-circles">
          <div className="terminal-circle"></div>
          <div className="terminal-circle"></div>
          <div className="terminal-circle"></div>
        </div>
      </div>
      <div className="terminal-body">
        <span>
          <span className="color-pink">import</span> requests
        </span>
        <br />
        <br />
        <span>API_KEY = </span>
        <span className="color-yellow">{"<MY-API-KEY-GOES-HERE>"}</span>
        <br />
        <br />
        <span>
          req <span className="color-pink">=</span> {`{`}
        </span>
        <br />
        <span className="indent" />
        <span className="color-yellow">{`"claim"`}</span>
        <span className="color-white">:</span>
        <span className="color-yellow">{` "A method of controlling braking of a towed vehicle said method comprising: receiving a speed signal based on the speed of a towing vehicle, or a towed vehicle, or both said towing vehicle and said towed vehicle; receiving a pressure signal based on a brake system of the said towing vehicle."`}</span>
        <span className="color-white">,</span>
        <br />
        <span className="indent" />
        <span className="color-yellow">{`"court_or_agency"`}</span>
        <span className="color-white">:</span>
        <span className="color-yellow">{` "cafc,cacd"`}</span>
        <span className="color-white">,</span>
        <br />
        <span className="indent" />
        <span className="color-yellow">{`"judges"`}</span>
        <span className="color-white">:</span>
        <span className="color-yellow">{` "Josephine L. Staton (cacd),Plager (cafc),Mayer (cafc)"`}</span>
        <span className="color-white">,</span>
        <br />
        <span className="indent" />
        <span className="color-yellow">{`"from_date"`}</span>
        <span className="color-white">:</span>
        <span className="color-yellow">{` "January 1, 2021"`}</span>
        <span className="color-white">,</span>
        <br />
        <span className="indent" />
        <span className="color-yellow">{`"to_date"`}</span>
        <span className="color-white">:</span>
        <span className="color-yellow">{` "August 1, 2021"`}</span>
        <span className="color-white">,</span>
        <br />
        <span className="indent" />
        <span className="color-yellow">{`"model_name"`}</span>
        <span className="color-white">:</span>
        <span className="color-yellow">{` "stk_alora"`}</span>
        <span className="color-white">,</span>
        <br />
        {`}`}
        <br />
        <br />
        <span>
          resp <span className="color-pink">=</span> requests.post{`(`}
        </span>
        <br />
        <span className="indent" />
        <span className="color-white">{`"https://api.stacksai.com/v1/101-eligibility/predict",`}</span>
        <br />
        <span className="indent" />
        <span>
          json<span className="color-pink">=</span>req,
        </span>
        <br />
        <span className="indent" />
        <span>
          headers=<span className="color-yellow">{`{"X-API-KEY": `}</span>
          API_KEY
        </span>
        {`},`}
        <br />
        {`)`}
        <br />
        <br />
        <span>
          print(resp<span className="color-pink">.</span>json())
        </span>
      </div>
    </div>
  );
};

export default Terminal;
