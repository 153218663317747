import React, { useState, useEffect } from "react";
import { useRequest } from "ahooks";
import { Link } from "react-router-dom";
import Layout from "../../layouts/Layout";
import BlogItem from "./BlogItem";
import Loading from "../loading";
import { getPosts } from "../../api";
import { Switch } from "antd";

const DashboardBlog = () => {
  const [nextToken, setNextToken] = useState(null);
  const [posts, setPosts] = useState([]);

  const { data, loading, refresh, run } = useRequest((value) =>
    getPosts(value || { nextToken, isPublished: true })
  );

  const fetchPosts = useRequest(getPosts, {
    manual: true,
  });

  useEffect(() => {
    if (!loading && data) {
      setPosts([]);
      setTimeout(() => {
        setPosts(data.items);
        setNextToken(data?.nextToken || null);
      }, 20);
    }
  }, [loading]);

  useEffect(() => {
    if (!fetchPosts.loading) {
      if (fetchPosts.data?.items) {
        setPosts([...posts, ...fetchPosts.data?.items]);
        setNextToken(fetchPosts?.data.nextToken || null);
      }
    }
  }, [fetchPosts.loading]);

  const loadPosts = async () => {
    await fetchPosts.run({ nextToken });
  };

  return (
    <Layout>
      <div className="blog-section">
        <div className="container-80">
          <h1 className="blog-post-heading">
            Blog
            <Link to="/dashboard/post/create">
              <button className="button button--white">Create Post</button>
            </Link>
          </h1>

          <Switch
            className="mb-50"
            checkedChildren="Live"
            unCheckedChildren="Draft"
            defaultChecked
            onChange={(value) => run({ nextToken: null, isPublished: value })}
          />

          {!loading && posts ? (
            <div className="blog-list">
              {posts.map((post) => (
                <BlogItem post={post} />
              ))}
            </div>
          ) : (
            <Loading />
          )}

          {!loading && posts.length === 0 && (
            <div className="row row-center">No posts</div>
          )}

          {fetchPosts.loading ? (
            <Loading />
          ) : (
            nextToken && (
              <div className="row row-center">
                <button onClick={() => loadPosts()} className="button">
                  Load more
                </button>
              </div>
            )
          )}
        </div>
      </div>
    </Layout>
  );
};

export default DashboardBlog;
