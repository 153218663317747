import React from "react";
import Layout from "../../layouts/Layout";
import Terminal from "./Terminal";
import QuickStartGuide from "./QuickStartGuide";
import GetStarted from "../getStarted/GetStarted";
import ReDocIcon from "../../assets/redoc.svg";
import OpenApiIcon from "../../assets/openapi.svg";
import SwaggerUiIcon from "../../assets/swagger-ui.svg";
import Seo from "../seo";

const Developer = () => {
  return (
    <>
      <Seo
        title="For Developers - Stacks"
        description="Stacks prioritizes developers - crucial role players in modern companies, financial institutions, and law firms."
        url={window.location.href}
        social="true"
      />
      <Layout>
        <div className="developer-section">
          <div className="container-80">
            <h1 className="text-center">
              Instant Integration with Stacks APIs
            </h1>

            <Terminal />

            <div className="developer-section-tech">
              <div className="tech-item">
                <img alt="" src={OpenApiIcon} />
                <h3>API 3.x compatible</h3>
                <p>
                  Use the link below to download our OpenAPI 3.x compatible API
                  Definition.
                </p>
                <a
                  href="https://api.stacksai.com/openapi.json"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="button">API Definition</button>
                </a>
              </div>
              <div className="tech-item">
                <img alt="" src={ReDocIcon} />
                <h3>ReDoc Interface</h3>
                <p>
                  We provide a modern ReDoc interface to search and read the
                  API's documentation easily.
                </p>
                <a
                  href="https://api.stacksai.com/v1/redoc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="button">ReDoc Interface</button>
                </a>
              </div>
              <div className="tech-item">
                <img alt="" src={SwaggerUiIcon} />
                <h3>Swagger UI</h3>
                <p>
                  With Swagger UI you can access, authorize, and test the API's
                  resources quickly.
                </p>
                <a
                  href="https://api.stacksai.com/v1/docs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="button">Swagger UI</button>
                </a>
              </div>
            </div>
            <QuickStartGuide />
          </div>
        </div>
      </Layout>
      <GetStarted />
    </>
  );
};

export default Developer;
