import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../layouts/Layout";
import { Storage } from "aws-amplify";
import { createPostApi } from "../../api";
import { toast } from "react-toastify";
import Loading from "../loading";
import { Upload, Modal, message, Switch } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import MDEditor from "@uiw/react-md-editor";

const Dashboard = () => {
  const [value, setValue] = useState("");
  const [isPublished, setPublished] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [uploadState, setUploadState] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    toast.dark("Loading!");

    setIsSaving(true);

    setTimeout(async () => {
      const title = e.target[0].value;
      const author = e.target[1].value;
      const caption = e.target[2].value;
      const description = e.target[3].value;
      const content = value;

      if (!image) {
        toast.error("Please upload featured image!");
        return;
      }

      const fileName = `${Date.now()}-${image.name}`;
      const res = await Storage.put(fileName, image, {
        contentType: image.type,
      });

      const input = {
        title,
        content,
        author,
        caption: caption || "",
        image: res.key,
        images: uploadState.fileList.map((file) => file.imgKey),
        description,
        isPublished: isPublished ? 1 : 0,
      };

      const isCreated = await createPostApi(input);

      if (isCreated) {
        toast.dark("Post created successfully");
        navigate("/dashboard/blog");
      }
    }, 300);
  };

  const removeImage = () => {
    const preview = document.getElementById("post-image");
    preview.src = "";
    setImage(null);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        Upload <br /> image
      </div>
    </div>
  );

  const handleCancel = () => {
    setUploadState({ ...uploadState, previewVisible: false });
  };

  const previewChange = (e) => {
    const preview = document.getElementById("post-image");
    const image = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = async () => {
      preview.src = reader.result + "";
      if (image.type === "image/jpeg" || image.type === "image/png") {
        setImage(image);
      } else {
        toast.error("Please upload jpeg or png!");
      }
    };
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const customRequest = (props) => {
    const { file, onSuccess } = props;

    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setUploadState({
      ...uploadState,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const handleChange = async ({ fileList, file: currentFile }) => {
    const file = fileList[fileList.length - 1];

    if (!file) {
      setUploadState({
        ...uploadState,
        fileList,
      });
      return;
    }

    if (currentFile.status === "removed") {
      setUploadState({
        ...uploadState,
        fileList,
      });
      return;
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG or PNG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    if (!isJpgOrPng || !isLt2M) return;

    setUploadState({
      ...uploadState,
      fileList: [...fileList.slice(0, -1), { status: "uploading", percent: 0 }],
    });

    const originFile = file.originFileObj;

    const fileName = `${Date.now()}-${originFile.name}`;

    const res = await Storage.put(fileName, originFile, {
      contentType: originFile.type,
    });

    setUploadState({
      ...uploadState,
      fileList: [
        ...fileList.slice(0, -1),
        { ...file, imgKey: res.key, status: "done" },
      ],
    });
  };

  const copyUrl = async (img) => {
    await navigator.clipboard.writeText(img.imgKey);
    toast.dark("image URL copied");
  };

  const fetchImage = async () => {
    const interval = setInterval(() => {
      const markdownElement = document.querySelector(".markdown-container");

      if (!markdownElement) return;

      const images = markdownElement.querySelectorAll("img");

      if (isSaving) {
        images?.forEach((img) => {
          const imgKey = img.getAttribute("img-key");
          img.src = imgKey;
        });
        clearInterval(interval);
      }

      images?.forEach((img) => {
        img.setAttribute("loading", "lazy");
        const imgKey = img.getAttribute("img-key");
        const imgSrc = img.getAttribute("src");

        if (!imgKey) {
          Storage.get(imgSrc).then((signedUri) => {
            fetch(signedUri).then((data) => {
              if (data.status === 200) {
                img.src = signedUri;
                img.setAttribute("img-key", imgKey);
              }
            });
          });
        }
      });
    }, 200);
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <Layout>
      <div className="blog-section">
        <div className="container-80">
          <h1>Create Post</h1>

          <form onSubmit={onSubmit}>
            <p className="form-title">Title</p>
            <input className="dark" type="text" placeholder="Title" required />

            <p className="form-title mt-10">Author</p>
            <input
              className="dark mt-10"
              type="text"
              placeholder="Author"
              required
            />

            <p className="form-title mt-10">Caption</p>
            <input className="dark mt-10" type="text" placeholder="Caption" />

            <p className="form-title mt-10">Description</p>
            <textarea
              className="dark mt-10 mb-10"
              placeholder="Post description..."
              rows={4}
              required
            />

            <p className="form-title mt-10">Content</p>
            <div className="markdown-container">
              <MDEditor value={value} onChange={setValue} height={500} />
              <MDEditor.Markdown source={value} className="mt-30" />
            </div>

            <div className="upload-wrapper">
              <input
                ref={inputRef}
                className="hide"
                type="file"
                onChange={previewChange}
              />

              {image ? (
                <>
                  <button
                    onClick={removeImage}
                    className="button button--white"
                  >
                    Remove featured image
                  </button>
                </>
              ) : (
                <button
                  type="button"
                  onClick={() => inputRef.current.click()}
                  className="button button--white"
                >
                  Upload featured image
                </button>
              )}

              <img className={!image && "hide"} alt="" id="post-image" />
            </div>

            <Upload
              customRequest={customRequest}
              listType="picture-card"
              fileList={uploadState.fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              itemRender={(item, img) => {
                return img.status === "uploading" ? (
                  <Loading />
                ) : (
                  <>
                    {item}
                    <p onClick={() => copyUrl(img)} className="ant-copy">
                      copy
                    </p>
                  </>
                );
              }}
            >
              {uploadState.fileList.length >= 8 ? null : uploadButton}
            </Upload>

            <Switch
              className="mt-50"
              checkedChildren="Live"
              unCheckedChildren="Draft"
              onChange={(value) => setPublished(value)}
            />

            <br />

            <button type="submit" className="button mt-50 mb-20">
              Create Post
            </button>
            <Modal
              visible={uploadState.previewVisible}
              title={uploadState.previewTitle}
              footer={null}
              centered
              onCancel={handleCancel}
            >
              <img
                alt="example"
                style={{ width: "100%" }}
                src={uploadState.previewImage}
              />
            </Modal>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
