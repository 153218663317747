import React, { useCallback, useState } from "react";
import { createRequestApi } from "../../api";
import { toast } from "react-toastify";
import { API } from "aws-amplify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const RecaptchaComponent = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isDisabled, setDisabled] = useState(false);

  const closeModal = () => {
    const modal = document.getElementById("modal-btn");
    modal.checked = false;
  };
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!executeRecaptcha) return;
      (async () => {
        try {
          setDisabled(true);
          const token = await executeRecaptcha("/");
          const fullname = e.target[0].value;
          const company = e.target[1].value;
          const email = e.target[2].value;
          const referral = e.target[3].value;

          setTimeout(() => {
            closeModal();
            createRequestApi({ fullname, company, email, referral });
            API.post("apiKey", "/request_api", {
              body: {
                email,
                company,
                fullname,
                referral,
                createdAt: new Date().toISOString(),
              },
            });
            setDisabled(false);
            toast.dark("Request sent successfully!");
            closeModal();
          }, 300);
        } catch (e) {
          setDisabled(false);
          console.log(e);
        }
      })();
    },
    [executeRecaptcha]
  );

  return (
    <div className="section">
      <input
        className="modal-btn"
        type="checkbox"
        id="modal-btn"
        name="modal-btn"
      />
      <label htmlFor="modal-btn" className="hide">Request API</label>
      <div className="modal">
        <form onSubmit={onSubmit} className="modal-wrap">
          <div onClick={() => closeModal()} className="model-icon-wrap">
            <span className="modal-icon">&nbsp;</span>
          </div>
          <h2>Request API</h2>
          <p>Enter your information below and we'll email you our API key.</p>
          <input
            className="dark mt-20"
            type="text"
            placeholder="Full name"
            required
          />
          <input
            className="dark mt-10"
            type="text"
            placeholder="Company"
            required
          />
          <input
            className="dark mt-10"
            type="email"
            placeholder="Email"
            required
          />
          <input
            className="dark mt-10"
            type="text"
            placeholder="Referral code (optional)"
          />
          <button type="submit" disabled={isDisabled} className="button mt-20">
            Request API
          </button>
        </form>
      </div>
    </div>
  );
};

const Modal = () => {
  return (
    <>
      <RecaptchaComponent />
    </>
  );
};

export default Modal;
