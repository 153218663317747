import React, { useEffect } from "react";
import { useRequest } from "ahooks";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Storage } from "aws-amplify";
import Layout from "../../layouts/Layout";
import Loading from "../loading";
import { getPostApi } from "../../api";
import MDEditor from "@uiw/react-md-editor";
import Share from "../share";
import LatestPosts from "./LatestPosts";
import Seo from "../seo";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

const Blog = () => {
  const { id } = useParams();

  const { data, loading } = useRequest(() => getPostApi(id), {
    ready: id,
  });

  const getDate = () => {
    let date = new Date(data.createdAt);
    if (data.updatedAt) {
      date = new Date(data.updatedAt);
    }
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return [month, day, year].join(".");
  };

  const DatePrint = (date) => {
    if (data.updatedAt) {
      return <div>Updated {getDate()}</div>;
    }
    return <div>{getDate()}</div>;
  };

  useEffect(() => {

  const fetchImage = async () => {
    let url = "";
    setTimeout(async () => {
      url = await Storage.get(data.image, {
        level: "public",
        expires: "604800",
      });
      const img = document.querySelector(".blog-img");
      if (img) img.src = url;
      let meta = document.head.querySelector("meta[property='og:image']");
      if (document.head && meta) {
        meta.setAttribute("property", "og:image");
        meta.content = url;
      }
    }, 100);
  };

  const fetchImages = async () => {
    setTimeout(() => {
      const markdownElement = document.querySelector(
        ".container-80 .markdown-container"
      );

      if (markdownElement) {
        const text = markdownElement.innerText;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);

        const timeElement = document.getElementById("time");
        if (timeElement) timeElement.innerText = time + " min read";
      }

      markdownElement.querySelectorAll("img").forEach((img) => {
        img.setAttribute("loading", "lazy");
        const imgKey = img.getAttribute("src");
        Storage.get(imgKey).then((signedUri) => (img.src = signedUri));
      });
    }, 100);
  };

    if (!loading && data) {
      fetchImage();
      fetchImages();
    }
  }, [loading]);

  const shareButtonStyle = {
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    backgroundColor: "#ccc",
    padding: "0.45rem",
  };
  const siteUrl = window.location.href;

  return (
    <>
      <Layout>
        <div className="blog-section">
          <div className="container-80">
            {!loading && data ? (
              <>
                <Seo
                  title={`${data.title} | Stacks Blog`}
                  url={siteUrl}
                  type="article"
                  twitterCardType="summary_large_image"
                  social="true"
                  description={data.description.length > 100
                    ? data.description.substring(0, 100) + "..."
                    : data.description}
                />
                <h1><strong>{data.title}</strong></h1>
                <Share
                  style={shareButtonStyle}
                  align="left"
                  url={siteUrl}
                  quote={data.title}
                  title={data.title}
                />
                <div className="blog-post">
                  <p className="blog-item-author">{data?.author}</p>
                  <div className="blog-item-date-comments flex-multiple">
                  <DatePrint />|<span id="time" className="item-time-comments"></span>
                    {/* <div className="item-time-comments">
                    <HyvorTalk.CommentCount
                      websiteId={parseInt(process.env.REACT_APP_HYVOR_ID)}
                      id={data.id}
                    />
                    </div> */}
                  </div>
                  <img className="blog-img" alt="" />
                  <p className="blog-img-caption">{data?.caption}</p>
                </div>
              </>
            ) : (
              <Loading />
            )}

            <div className="markdown-container mt-30">
              <MDEditor.Markdown source={data?.content} className="mt-30" />
            </div>
            <Share
              style={shareButtonStyle}
              align="left"
              url={siteUrl}
              quote={data?.title}
              title={data?.title}
            />
            {/* <HyvorTalk.Embed
              websiteId={parseInt(process.env.REACT_APP_HYVOR_ID)}
              id={data?.id}
            /> */}
            <LatestPosts id={id} />
            {/* Load Comments now */}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default withRouter(Blog);
