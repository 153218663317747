import Helmet from "react-helmet";
import React from "react";
import stacksImage from "../assets/stacks-social-media.png";

const Seo = ({
  image,
  title,
  url,
  description,
  type,
  social,
  twitterCardType,
}) => {
  const metaDescription = description || null;
  const siteUrl = url || "https://stacksai.com/";
  const defaultTitle = title || "Stacks";
  const defaultType = type || "website";
  const defualtImg = image || "https://stacksai.com" + stacksImage;
  const siteTwitter = "@stacks_ai";

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" key={siteUrl} to={siteUrl} />
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:title" content={defaultTitle} />
        {metaDescription && (
          <meta name="og:description" content={metaDescription} />
        )}
        <meta property="og:type" content={defaultType} />
        {defualtImg && <meta property="og:image" content={defualtImg} />}
        {metaDescription && (
          <meta name="twitter:description" content={metaDescription} />
        )}
        {social && <meta name="twitter:title" content={defaultTitle} />}
        {social && <meta name="twitter:site" content={siteTwitter} />}
        {social && <meta name="twitter:url" content={siteUrl} />}
        {social && <meta name="twitter:card" content={twitterCardType || "summary"} />}
      </Helmet>
    </>
  );
};

export default Seo;
