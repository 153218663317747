import React from "react";
import Layout from "../../layouts/Layout";
import Contact from "../home/Contact";
import GetStarted from "../getStarted/GetStarted";
import SearchPropertyIcon from "../../assets/SearchProperty.png";
import ShowPropertyIcon from "../../assets/ShowProperty.png";
import Seo from "../seo";

const Solutions = () => {
  return (
    <>
      <Seo title="Solutions - Stacks" description="Use Stacks solutions to find out if your patent is infringing your competitors' patents or if your products are eligible for a patent under the Alice test." url={window.location.href} />
      <Layout>
        <div className="solutions-section">
          <div className="container-80">
            <h1 className="service-h1">Infringement Research</h1>

            <div data-aos="fade-up" className="feature">
              <img src={ShowPropertyIcon} alt="Show Property" />
              <h4>
              You and your team have been pushing the boundaries of science and engineering, and you have
              been awarded patents for your innovations and discoveries. But how do you know if anyone is
              infringing your patents?
              </h4>
            </div>

            <div data-aos="fade-up" className="feature">
              <img src={SearchPropertyIcon} alt="Search Property" />
              <h4>
              Meet patent infringement research tool. This tool automatically compares your competitors’
              products against your patent portfolio and instantly tells you if and which of your claimed
              inventions in the patents could be infringed by a product.
              </h4>
            </div>
          </div>

          <GetStarted tryNowUrl={process.env.REACT_APP_INFRINGEMENT_URL}/>
        </div>
      </Layout>
      <Contact />
    </>
  );
};

export default Solutions;
