import React, { useState, useEffect } from "react";
import { useRequest } from "ahooks";
import { useNavigate } from "react-router-dom";
import Layout from "../../layouts/Layout";
import { createNoteApi, getNoteApi, updateNoteApi } from "../../api";
import { toast } from "react-toastify";
import Loading from "../loading";
import MDEditor from "@uiw/react-md-editor";

const Privacy = () => {
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const { data, loading } = useRequest(() => getNoteApi("privacy-policy"));

  useEffect(() => {
    if (!loading && data) {
      setValue(data.content || "");
    } else if (!loading && !data) {
      createNoteApi("privacy-policy");
    }
  }, [loading]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const content = value;

    toast.dark("Loading!");

    const isUpdated = await updateNoteApi({ id: "privacy-policy", content });

    if (isUpdated) {
      toast.dark("Privacy policy updated successfully");
      navigate("/dashboard");
    }
  };

  return (
    <Layout>
      <div className="blog-section">
        <div className="container-80">
          <h1>Privacy Policy Editor</h1>

          {loading ? (
            <Loading />
          ) : (
            <>
              <form onSubmit={onSubmit}>
                <div className="markdown-container">
                  <MDEditor value={value} onChange={setValue} height={500} />
                </div>

                <button type="submit" className="button mt-50 mb-20">
                  Update
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Privacy;
