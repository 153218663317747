import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../../contexts/user";

const AuthPage = ({ children}) => {
  const [user] = useUser();

  return !user ? children : <Navigate to="/dashboard" />;
};

export default AuthPage;
