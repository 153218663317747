import React from "react";
import Layout from "../../layouts/Layout";
import Contact from "../home/Contact";
import GetStarted from "../getStarted/GetStarted";
import BrainstormSkillIcon from "../../assets/BrainstormSkill.png";
import MotherboardIcon from "../../assets/Motherboard.png";
import LawBookIcon from "../../assets/LawBook.png";
import AliceTestDotComIcon from "../../assets/AliceTestDotComIcon.png";

const Solutions = () => {
  return (
    <>
      <Layout>
        <div className="solutions-section">
          <div className="container-80">
            <h1 className="service-h1">Patent Lawyer Finder</h1>

            <div data-aos="fade-up" className="feature">
              <img src={MotherboardIcon} alt="Do patent lawyers understand the technical side?" />
              <h4>
              Patent is a legal and a technical document. Any good patent lawyer could understand and handle the legal side, but how about the technical side of your invention?
              </h4>
            </div>

            <div data-aos="fade-up" className="feature">
              <img src={BrainstormSkillIcon} alt="Patent Lawyer Finder is like a matchmaker for inventors" />
              <h4>
              <it>Patent Lawyer Finder</it> is like a matchmaker for inventors. We match your invention with the patent lawyers who have handled similar inventions from the last ten years, giving you a list of highly experienced lawyers in your field.
              </h4>
            </div>

            <div data-aos="fade-up" className="feature">
              <img src={LawBookIcon} alt="Scope of the AI" />
              <h4>
              This independent, data-driven, AI-powered service compares your invention to all the US patents that every single patent lawyer has filed in the United States since 2005.
              </h4>
            </div>

            <div data-aos="fade-up" className="feature">
                  <img src={AliceTestDotComIcon} alt="Ensure quality"/>
                  <h4> It ensures that the lawyers understand your technical invention and can help you properly patent and protect your invention.
                  </h4>
            </div>
          </div>
          <GetStarted tryNowUrl={process.env.REACT_APP_LAWYER_FINDER_URL}/>
        </div>
      </Layout>
      <Contact />
    </>
  );
};

export default Solutions;
