import React from "react";
import Layout from "../../layouts/Layout";
import Seo from "../seo"
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <Seo title="Contact Us" descripion="If you want to address patent eligibility and infringement risks, don't wait - get in touch with us today!" url={window.location.href} />
      <Layout darker full>
        <div className="contact-section">
          <div className="container-80">
            <div className="get-in-touch">
              <h1 className="color-white">Get in touch</h1>
              <div className="color-white mb-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{ width: 24, height: 24 }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <a href="mailto:support@stacksai.com">support@stacksai.com</a>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{ width: 24, height: 24 }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <a href="tel:+14802134803">+1 (480) 213-4803</a>
              </div>
              <a
                  onClick={
                    ()=>{
                        window.open(process.env.REACT_APP_CALENDLY_URL, '_self', 'noopener, noreferrer');
                    }
                  }
                    className="button button--white mt-20"
                  >
                  Book a Demo
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Contact;
