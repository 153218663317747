import React from "react";
import ReactDOM from "react-dom";
import "./styles/main.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {Amplify,  Hub} from "aws-amplify";
import awsmobile from "./aws-exports";
import { UserProvider } from "./contexts";
import { BrowserRouter as Router } from "react-router-dom";

const aws_exports_override = {
  ...awsmobile,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
};

Hub.listen("auth", (data) => {
  const { payload } = data;

  if (payload.event === "signIn") {
    Amplify.configure(aws_exports_override);
  } else if (payload.event === "signOut") {
    Amplify.configure(awsmobile);
  }
});

const isDashboard = window.location.pathname.startsWith("/dashboard");

Amplify.configure(isDashboard ? aws_exports_override : awsmobile);

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
    <Router>
      <App />
    </Router>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
