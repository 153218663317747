import React from "react";
import PinkVectorIcon from "../../assets/PinkVector.svg";
import StacksLogo from "../../assets/Logo.png";
import Buttons from "../../components/getstartedButtons"


const GetStarted = ({tryNowUrl = null}) => {
  return (
    <div
      data-aos="zoom-in"
      className="get-started"
      style={{ backgroundImage: `url(${PinkVectorIcon})` }}
    >
      <div className="container-60">
        <img style={{ width: 50 }} src={StacksLogo} alt="Logo" />
        <h1 className="get-started-h1">Ready to get started?</h1>
        <p>
          Stacks solutions help you address patent eligibility and infringement
          risks quickly and intelligently!
        </p>
        <Buttons tryNowUrl={tryNowUrl} />
      </div>
    </div>
  );
};

export default GetStarted;
