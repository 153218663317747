import React from "react";
import {
  InferenceIcon,
  BenefitsIcon,
  CollectDataIcon,
  DesignModelsIcon,
  TrainModelsIcon,
} from "./Icons";
import aliceTestIcon from "../../assets/aliceTest.svg";
import dependableToolIcon from "../../assets/dependableTool.svg";
import economicGainsIcon3 from "../../assets/economicGains3.svg";

const AliceTest = () => {
  return (
    <>
      <div className="home-section-stacks">
        <div className="container-80">
          <div data-aos="fade-up" className="home-hero-h1-wrapper">
            <h1 className="f-w-600 text-center color-light-blue">
              101 Eligibility Analyzer
            </h1>
          </div>

          <div data-aos="fade-up" className="home-hero-h1-wrapper">
            <h3 className="f-w-600 color-blue text-center mt-10 mb-50">
              Act proactively to assess patent eligibility of your inventions
              under the Alice test.
            </h3>
          </div>

          <div data-aos="fade-up" className="home-hero-h1-wrapper">
            <h2 className="f-w-600 color-blue mt-30">How it works</h2>
          </div>

          <div className="home-how-it-works">
            <div data-aos="zoom-in" data-aos-delay="50">
              <CollectDataIcon />
              <h3>Collect</h3>
              <p>
                We first collected the claims from all the litigated patents and
                labeled related cases involving 101 eligibility.
              </p>
            </div>
            <div data-aos="zoom-in" data-aos-delay="150">
              <DesignModelsIcon />
              <h3>Design</h3>
              <p>
                We then designed novel machine learning models suitable for
                understanding 101 eligibility.
              </p>
            </div>
            <div data-aos="zoom-in" data-aos-delay="250">
              <TrainModelsIcon />
              <h3>Train</h3>
              <p>
                Next, we trained the models on the composed dataset to tell what
                claims pass or fail the Alice test.
              </p>
            </div>
            <div data-aos="zoom-in" data-aos-delay="350">
              <InferenceIcon />
              <h3>Deploy</h3>
              <p>
                We built an API endpoint for the models. Enter your data and
                check if your claim may pass the Alice test or not.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="home-section-stacks-introduction">
        <div className="container-80">
          <div data-aos="zoom-in-right" className="home-section-introduction">
            <div className="home-introduction-h2-wrapper">
              <h2 className="f-w-600 color-blue">
                What is 101 Eligibility Analyzer?
              </h2>
            </div>
            <p>
              Determining patent eligibility of a claim under 35 U.S.C. 101 can
              turn into a complicated, daunting task. The Supreme Court of the
              United States has introduced a two-step framework named the "Alice
              test" to assess the subject matter eligibility. The main challenge
              is that the judicial branch has not provided an exact definition
              or a guideline for the Alice test other than instructing us to
              learn how it applied the test in prior cases.
            </p>
            <p>
              101 Eligibility Analyzer API is a data-driven system that
              systematically combines all of the prior litigated or decided
              patent claims under Section 101 and applies the collective
              knowledge of these cases to a new suit to predict the eligibility
              of the claims in suit under the Alice test.
            </p>
          </div>

          <div data-aos="zoom-in-left" className="home-section-benefit">
            <BenefitsIcon />
            <div className="home-benefit-text-wrapper">
              <h3 className="f-w-600 color-blue">Advantages</h3>

              <div className="benefits">
                <p>Perform 101 analysis at scale quickly and cheaply.</p>
                <p className="indentation">
                  Examine how the subject matter eligibility of your patents can
                  be affected by the new cases.
                </p>
                <p>Explore and locate the similar and relevant cases.</p>
                <p className="indentation">
                  Get the right results fast with advanced deep learning and
                  natural language processing technologies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-section-economic-gains">
        <div className="container-80">
          <img alt="" data-aos="fade-right" src={aliceTestIcon} />
          <div data-aos="fade-left" className="home-section-similar">
            <div className="home-machine-text-wrapper">
              <h2 className="f-w-600 color-blue">
                Machine learning and the Alice test
              </h2>

              <p>
                The Supreme Court did not delimit the precise contours of the
                abstract ideas or inventive steps. Instead, it asks to follow
                the examples set by the court system.
              </p>

              <p>
                The lack of a definitive rule and the presence of prior cases as
                the central guidance to evaluate and understand notions such as
                abstractness or inventive steps suggest that machine learning
                can be a proper tool to help model and apply the Alice test.
              </p>

              <p>
                The engine behind 101 Eligibility Analyzer is trained on the
                prior litigated cases to learn from them and use them as
                instructive examples to predict the outcome of the new cases.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="home-section-economic-gains">
        <div className="container-80">
          <div data-aos="fade-down" className="home-section-similar">
            <div className="home-machine-text-wrapper">
              <h2 className="f-w-600 color-blue">
                A dependable tool for a volatile law
              </h2>
              <p>
                The judicial interpretations of abstract ideas and inventive
                steps have been quite fluid and changing over time. A new case
                could invalidate a previously valid patent overnight.
              </p>

              <p>
                Stacks regularly updates its data and retrains its models with
                the latest judicial decisions to deliver the newest
                interpretation of the Alice test. Therefore, Stacks API can help
                users re-evaluate an entire patent portfolio in seconds and stay
                up to date.
              </p>
            </div>
          </div>
          <img alt="" data-aos="fade-up" src={dependableToolIcon} />
        </div>
      </div>

      <div className="home-section-economic-gains">
        <div className="container-80">
          <img alt="" data-aos="zoom-in-right" src={economicGainsIcon3} />
          <div data-aos="zoom-in-left" className="home-section-similar">
            <div className="home-machine-text-wrapper">
              <h2 className="f-w-600 color-blue">
                Who should use this and why?
              </h2>

              <ul>
                <li>
                  IP owners, in-house counsels, investors, IP traders and other
                  stakeholders because they need to evaluate their IP portfolio
                  and avoid expensive outsourcing.
                </li>

                <li>
                  Patent law practitioners because they have to navigate the
                  landscape with the latest and most relevant updates of the law
                  quickly.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AliceTest;
