import { useEffect } from "react";
import { useParams, Route, Routes, Link } from "react-router-dom";
import Home from "./components/home/Home";
import Page from "./components/routing/Page";
import AuthPage from "./components/routing/AuthPage";
import PrivatePage from "./components/routing/PrivatePage";
import Navbar from "./layouts/Navbar";
import Footer from "./layouts/Footer";
import Modal from "./components/modal/Modal";
import NotFound from "./components/notfound/NotFound";
import Blog from "./components/blog/Blog";
import BlogPost from "./components/blog/BlogPost";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Developer from "./components/developer/Developer";
import InfringementResearch from "./components/solutions/InfringementResearch";
import PatentClearance from "./components/solutions/PatentClearance";
import EligibilityAnalyzer from "./components/solutions/EligibilityAnalyzer";
import PatentLawyerFinder from "./components/solutions/PatentLawyerFinder";
import Privacy from "./components/privacy/Privacy";
import Terms from "./components/terms/Terms";
import Dashboard from "./components/dashboard/Dashboard";
import DashboardBlog from "./components/dashboard/Blog";
import CreatePost from "./components/dashboard/CreatePost";
import EditPost from "./components/dashboard/EditPost";
import Login from "./components/dashboard/Login";
import PrivacyEditor from "./components/dashboard/Privacy";
import TermsEditor from "./components/dashboard/Terms";
import Team from "./components/dashboard/Team";
import ScrollToTop from "./components/scrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { cssTransition } from "react-toastify";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import { useLocation } from 'react-router-dom';

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  collapse: false,
});

const BP = () =>{ 
  let { id } = useParams();
  return <>
      <BlogPost key={id || ''} />
    </>
};

const App = () => {

  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    ReactGA.pageview(currentPath + searchParams);
  }, [location]);
  
  useEffect(() => {
    AOS.init({
      duration: 900,
      once: true,
      easing: "ease-in-out-cubic",
    });
  }, []);

  return (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
        scriptProps={{ async: true }}
      >
        <ScrollToTop />
        <Navbar />
        <Modal />
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="cookie-consent"
          buttonStyle={{ fontSize: "13px" }}
          expires={150}
          style={{
          background: "black"
          }}
        >
          This website uses cookies to ensure you get the best experience on our
          website. <Link to="/privacy/#cookie-policy">Learn more</Link>
        </CookieConsent>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          transition={Zoom}
        />
        <Routes>
          <Route exact path="/" element={<><Page /><Home /></>} />
          <Route 
          exact
          path="/blog" element={<><Page title="Stacks Blog" /><Blog /></>} 
        />
        <Route 
          exact
          path="/blog/:id"
          element={<BP />} 
        />
        <Route 
          exact
          path="/about"
          element={<><Page title="About Us - Stacks" /><About /></>} 
        />
        <Route 
          exact
          path="/contact"
          element={<><Page title="Contact - Stacks" /><Contact /></>} 
        />
        <Route 
           exact
           path="/developer" 
           element={<><Page title="For Developers - Stacks" /><Developer /></>} 
        />
        <Route
          exact
          path="/solutions/101-eligibility-analyzer"
          element={<><Page title="101 Eligibility Analyzer - Stacks" /><EligibilityAnalyzer /></>}
        />
        <Route
          exact
          path="/solutions/infringement-research"
          element={<><Page title="Infringement Research - Stacks" /><InfringementResearch /></>}
        />
        <Route
          exact
          path="/solutions/patent-clearance"
          element={<><Page title="Patent Lawyer Finder - Stacks" /><PatentClearance /></>}
        />
        <Route
          exact
          path="/solutions/patent-lawyer-finder"
          element={<><Page title="Patent Lawyer Finder - Stacks" /><PatentLawyerFinder /></>}
        />
        <Route
          exact
          path="/privacy"
          element={<><Page title="Privacy Policy" /><Privacy /></>}
        />
        <Route 
          exact 
          path="/terms" 
          element={<><Page title="Terms of Service" /><Terms /></>}
        />
        <Route
          exact
          path="/dashboard"
          element={<PrivatePage><Page title="Dashboard" /><Dashboard /></PrivatePage>}
        />
        <Route
          exact
          path="/dashboard/team"
          element={<PrivatePage><Page title="Team" /><Team /></PrivatePage>}
        />
        <Route
          exact
          path="/dashboard/blog"
          element={<PrivatePage><Page title="Blog" /><DashboardBlog /></PrivatePage>}
        />
        <Route
          exact
          path="/dashboard/post/create"
          element={<><Page title="Create Post" /><CreatePost /></>}
        />
        <Route
          exact
          path="/dashboard/post/edit/:id"
          element={<PrivatePage><Page title="Edit Post" /><EditPost /></PrivatePage>}
        />
        <Route
          exact
          path="/dashboard/privacy"
          element={<PrivatePage><Page title="Privacy Policy" /><PrivacyEditor /></PrivatePage>}
        />
        <Route
          exact
          path="/dashboard/terms"
          element={<PrivatePage><Page title="Terms of Service"/><TermsEditor /></PrivatePage>}
        />

        <Route
          exact
          path="/dashboard/login"
          element={<AuthPage><Page title="Login" /><Login /></AuthPage>}
        />
        <Route 
          exact 
          path="*" 
          element={<><Page title="Not Found - Stacks" /><NotFound /></>} 
        />
        </Routes>
        <Footer />
      </GoogleReCaptchaProvider>
  );
};

export default App;
