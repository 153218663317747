import React, { useState, useEffect } from "react";
import { useRequest } from "ahooks";
import { useNavigate } from "react-router-dom";
import Layout from "../../layouts/Layout";
import { createNoteApi, getNoteApi, updateNoteApi } from "../../api";
import { toast } from "react-toastify";
import Loading from "../loading";
import MDEditor from "@uiw/react-md-editor";

const Terms = () => {
  const [showMarkdown, setShowMarkdown] = useState(true);
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const { data, loading } = useRequest(() => getNoteApi("terms-of-service"));

  useEffect(() => {
    if (!loading && data) {
      setValue(data.content || "");
    } else if (!loading && !data) {
      createNoteApi("terms-of-service");
    }
  }, [loading]);

  const onSubmit = async (e) => {
    e.preventDefault();

    const content = value;

    toast.dark("Loading!");

    const isUpdated = await updateNoteApi({ id: "terms-of-service", content });

    if (isUpdated) {
      toast.dark("Terms of service updated successfully");
      navigate("/dashboard");
    }
  };

  return (
    <Layout>
      <div className="blog-section">
        <div className="container-80">
          <h1>Terms of Service Editor</h1>

          {loading ? (
            <Loading />
          ) : (
            <>
              <button
                onClick={() => setShowMarkdown(!showMarkdown)}
                className="markdown-toggle button button--white"
              >
                {/* Toggle */}
                {showMarkdown ? "Markdown" : "Editor"}
              </button>

              <form onSubmit={onSubmit}>
                <div className="markdown-container">
                  <MDEditor value={value} onChange={setValue} />
                </div>

                <button type="submit" className="button mt-50 mb-20">
                  Update
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Terms;
