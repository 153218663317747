import React from "react";
import {
  InferenceIcon,
  BenefitsIcon,
  CollectDataIcon,
  DesignModelsIcon,
  TrainModelsIcon,
} from "./Icons";
import languageAgnosticEngineIcon from "../../assets/languageAgnosticEngine.svg";
import extensiveResearchIcon from "../../assets/extensiveResearch.svg";

const InfringementResearch = () => {
  return (
    <>
      <div className="home-section-stacks">
        <div className="container-80">
          <div data-aos="fade-up" className="home-hero-h1-wrapper">
            <h1 className="f-w-600 text-center color-light-blue">
             Infringement Research
            </h1>
          </div>

          <div data-aos="fade-up" className="home-hero-h1-wrapper">
            <h3 className="f-w-600 color-blue text-center mt-10 mb-50">
              Understand technological similarities between your products and
              competitors' patents.
            </h3>
          </div>

          <div data-aos="fade-up" className="home-hero-h1-wrapper">
            <h2 className="f-w-600 color-blue mt-30">How it works</h2>
          </div>

          <div className="home-how-it-works">
            <div data-aos="zoom-in" data-aos-delay="50">
              <CollectDataIcon />
              <h3>Collect</h3>
              <p>
                We first collected a large number of patents and relevant
                product descriptions and created a dataset.
              </p>
            </div>
            <div data-aos="zoom-in" data-aos-delay="150">
              <DesignModelsIcon />
              <h3>Design</h3>
              <p>
                We then designed a special machine learning model suitable for
                measuring technological similarity.
              </p>
            </div>
            <div data-aos="zoom-in" data-aos-delay="250">
              <TrainModelsIcon />
              <h3>Train</h3>
              <p>
                Next, we trained the model on the dataset to spot, interpret,
                and measure similarities between two inputs.
              </p>
            </div>
            <div data-aos="zoom-in" data-aos-delay="350">
              <InferenceIcon />
              <h3>Deploy</h3>
              <p>
                We built an API for this model. Enter your data and expect an
                infringement probability if any similarity is found.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="home-section-stacks-introduction">
        <div className="container-80">
          <div data-aos="zoom-in-right" className="home-section-introduction">
            <div className="home-introduction-h2-wrapper">
              <h2 className="f-w-600 color-blue">
                Who should use this and why?
              </h2>
            </div>
            <ul>
              <li>
                New patent applicants who want to understand the prior
                art.
              </li>
              <li>
                Product managers because they need to discover any overlap
                between their product lineup and competitors' patents.
              </li>
              <li>
                IP owners who need to discover who is infringing their
                patent.
              </li>
              <li>
                Investment and financial institutions because they must assess
                the freedom-to-operate of a company or audit a product or a
                patent portfolio.
              </li>
            </ul>
          </div>

          <div data-aos="zoom-in-left" className="home-section-benefit">
            <BenefitsIcon />
            <div className="home-benefit-text-wrapper">
              <h3 className="f-w-600 color-blue">Advantages</h3>

              <div className="benefits">
                <p>Perform IP research at scale quickly and cheaply.</p>
                <p className="indentation">
                  Stay on top of your company's patent portfolio.
                </p>
                <p>
                  Monitor and keep track of new patents that could affect your
                  business.
                </p>
                <p className="indentation">
                  Get the right results fast with advanced deep learning and
                  natural language processing technologies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-section-economic-gains">
        <div className="container-80">
          <img alt="" data-aos="fade-right" src={languageAgnosticEngineIcon} />
          <div data-aos="fade-left" className="home-section-similar">
            <div className="home-machine-text-wrapper">
              <h2 className="f-w-600 color-blue">Language agnostic engine</h2>
              <p>
                The use of obscure language within patent claims is widespread.
                So a conventional search engine that only picks up the textual
                similarity between a query and claims cannot do justice to
                users. Infringement Research API understands and measures
                technological similarity regardless of terminology. Provide an
                invention or product description, and get a list of claims that
                overlap with the description technology-wise.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="home-section-economic-gains">
        <div className="container-80">
          <div data-aos="fade-down" className="home-section-similar">
            <div className="home-machine-text-wrapper">
              <h2 className="f-w-600 color-blue">
                Extensive research <br /> with simple steps
              </h2>
              <p>
                With Infringement Research API, you can run an in-depth,
                extensive IP analysis with a few clicks of a button. For
                example, analyze if thousands of product descriptions infringe
                an extensive patent portfolio quickly and easily.
              </p>
            </div>
          </div>
          <img alt="" data-aos="fade-up" src={extensiveResearchIcon} />
        </div>
      </div>
    </>
  );
};

export default InfringementResearch;
