import { Auth, API, graphqlOperation } from "aws-amplify";
import { getPost, messagesByDate, postsByPublished, requestsByDate, getNote} from "../graphql/queries";
import {
  createMessage,
  createNote,
  deleteRequest,
  updateNote,
  createPost,
  deletePost,
  updatePost,
  deleteMessage,
  updateRequest,
  createRequest
} from "../graphql/mutations";
import { toast } from "react-toastify";

const catchError = (err) => {
  const isDashboard = window.location.pathname.startsWith("/dashboard");
  const isLogin = window.location.pathname.startsWith("/dashboard/login");

  if (err.response) {
    if (err.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      toast.warn("You're unauthorized, please sign in");
    } else if (err.response.status === 403) {
      toast.warn("Unauthorized action");
    }
  } else if (err.message === "Network Error") {
    toast.error("Error occured, please try again");
  } else {
    if (err === "No current user") return;
    if (!isDashboard || isLogin) return;

    if (err.message) toast.error(err.message);
    else {
      if (typeof err === "string") {
        toast.error(err);
      }
    }
    console.log(err);
  }
};

// #region [Auth]
export const me = async () => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    return currentUser;
  } catch (err) {
    console.log("auth error:", err);
    catchError(err);
    return false;
  }
};

export const login = async (data) => {
  try {
    const { email, password } = data;
    await Auth.signIn({ username: email, password });
    return true;
  } catch (err) {
    console.log("Auth Error:", err);
    catchError(err);
    return false;
  }
};

export const logout = async () => {
  try {
    await Auth.signOut();
    return true;
  } catch (err) {
    console.log("Auth Error:", err);
    catchError(err);
    return false;
  }
};
// #endregion

// #region [Blog]

// Slugify a string
const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, "");
  str = str.toLowerCase();

  var from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  var to =
    "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");

  return str;
};

export const createPostApi = async (data) => {
  try {
    const {
      title,
      content,
      author,
      caption,
      description,
      image,
      images,
      isPublished,
    } = data;

    const input = {
      id: slugify(title),
      title,
      content,
      caption,
      author,
      description,
      image,
      images,
      typename: "Post",
      isPublished,
      createdAt: new Date().toISOString(),
    };

    await API.graphql(graphqlOperation(createPost, { input }));
    return true;
  } catch (err) {
    console.log("Blog Error:", err);
    if (
      err.errors &&
      err.errors[0].errorType === "DynamoDB:ConditionalCheckFailedException"
    ) {
      toast.error("Post title already exists!");
    }
    // catchError(err);
    return false;
  }
};

export const updatePostApi = async (data) => {
  try {
    await API.graphql(graphqlOperation(updatePost, { input: data }));
    return true;
  } catch (err) {
    console.log("Blog Error:", err);
    catchError(err);
    return false;
  }
};

export const deletePostApi = async (id) => {
  try {
    await API.graphql(graphqlOperation(deletePost, { input: { id } }));
    return true;
  } catch (err) {
    console.log("Blog Error:", err);
    catchError(err);
    return false;
  }
};

export const deleteMessageApi = async (id) => {
  try {
    await API.graphql(graphqlOperation(deleteMessage, { input: { id } }));
    return true;
  } catch (err) {
    console.log("Delete Message Error:", err);
    catchError(err);
    return false;
  }
};

export const deleteRequestApi = async (id) => {
  try {
    await API.graphql(graphqlOperation(deleteRequest, { input: { id } }));
    return true;
  } catch (err) {
    console.log("Request Delete Error:", err);
    catchError(err);
    return false;
  }
};

export const verifyRequestApi = async (data) => {
  const {id, isVerified} = data;

  try {
    await API.graphql(graphqlOperation(updateRequest, { input: { id, isVerified} }));
    return true;
  } catch (err) {
    console.log("Request Update Error:", err);
    catchError(err);
    return false;
  }
};

export const getPosts = async (data) => {
  try {
    const { nextToken, isPublished } = data;

    const res = await API.graphql(
      graphqlOperation(postsByPublished, {
        isPublished: isPublished ? 1 : 0,
        typename: "Post",
        limit: 8,
        sortDirection: "DESC",
        nextToken,
      })
    );

    const posts = res?.data?.postsByPublished;
    return posts;
  } catch (err) {
    console.log("Blog Error:", err);
    catchError(err);
    return false;
  }
};

export const getLatestPosts = async (data) => {
  try {
    const { id } = data;
    const res = await API.graphql(
      graphqlOperation(postsByPublished, {
        filter: { not: { id: { eq: id } } },
        isPublished: 1,
        typename: "Post",
        limit: 10,
        sortDirection: "DESC",
      })
    );

    const posts = res?.data?.postsByPublished.items;
    return posts;
  } catch (err) {
    console.log("Blog Error:", err);
    catchError(err);
    return false;
  }
};

export const getPostApi = async (id) => {
  try {
    const res = await API.graphql(graphqlOperation(getPost, { id }));
    const post = res?.data?.getPost;
    return post;
  } catch (err) {
    console.log("Blog Error:", err);
    catchError(err);
    return false;
  }
};

// #endregion

// #region [Message]

export const createMessageApi = async (data) => {
  try {
    const input = {
      ...data,
      typename: "Message",
      createdAt: new Date().toISOString(),
    };

    await API.graphql(graphqlOperation(createMessage, { input }));
    return true;
  } catch (err) {
    console.log("Message Error:", err);
    catchError(err);
    return false;
  }
};

export const getMessages = async () => {
  try {
    const res = await API.graphql(
      graphqlOperation(messagesByDate, {
        typename: "Message",
        limit: 20,
        sortDirection: "DESC",
      })
    );
    const messages = res?.data?.messagesByDate;
    return messages;
  } catch (err) {
    console.log("Message Error:", err);
    catchError(err);
    return false;
  }
};

// #endregion

// #region [Request]

export const createRequestApi = async (data) => {
  try {
    const input = {
      ...data,
      typename: "Request",
      createdAt: new Date().toISOString(),
      isVerified: false,
    };

    await API.graphql(graphqlOperation(createRequest, { input }));
    return true;
  } catch (err) {
    console.log("Request Error:", err);
    catchError(err);
    return false;
  }
};

export const getRequests = async () => {
  try {
    const res = await API.graphql(
      graphqlOperation(requestsByDate, { filter: { isVerified: { eq: false }},
        typename: "Request",
        limit: 20,
        sortDirection: "DESC",
      })
    );
    const requests = res?.data?.requestsByDate;
    return requests;
  } catch (err) {
    console.log("Request Error:", err);
    catchError(err);
    return false;
  }
};

// #region [Message]

export const getNoteApi = async (id) => {
  try {
    const noteRes = await API.graphql(graphqlOperation(getNote, { id }));
    return noteRes.data.getNote;
  } catch (err) {
    console.log("err:", err);
    return false;
  }
};

export const updateNoteApi = async (data) => {
  const { id, content } = data;

  try {
    await API.graphql(
      graphqlOperation(updateNote, {
        input: { id, content, images: data?.images || [] },
      })
    );
    return true;
  } catch (err) {
    console.log("err:", err);
    return false;
  }
};

export const createNoteApi = async (id) => {
  try {
    await API.graphql(
      graphqlOperation(createNote, { input: { id, content: "" } })
    );
    return true;
  } catch (err) {
    console.log("err:", err);
    return false;
  }
};

// #endregion
