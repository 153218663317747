import React from "react";

const NotFound = () => (
  <div className="section-home-hero">
    <div className="container-80">
      <div className="notfound">
        <div className="notfound-404">
          <h1>
            404
          </h1>
        </div>
        <h2>The page you requested could not be found!</h2>
      </div>
    </div>
  </div>
);
export default NotFound;
