import React, { useCallback, useState } from "react";
import Layout from "../../layouts/Layout";
import { createMessageApi } from "../../api";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { API } from "aws-amplify";

const RecaptchaComponent = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isDisabled, setDisabled] = useState(false);

  // Create an event handler so you can call the verification on button click event or form submit
  const onSubmit = useCallback(

    (e) => {
      
      e.preventDefault();
      if (!executeRecaptcha) return;
      (async () => {
        try {
          setDisabled(true);
          const token = await executeRecaptcha("/");
          const subject = e.target[0].value;
          const email = e.target[1].value;
          const message = e.target[2].value;


          const data = { subject, email, message };

          if (await createMessageApi(data)) {
            e.target.reset();
            API.post("apiKey", "/email_message", {
              body: {
                subject,
                email,
                message,
                createdAt: new Date().toISOString(),
              },
            });
            toast.dark("Message sent successfully!");
            setDisabled(false);
          }
        } catch (e) {
          setDisabled(false);
          console.log(e);
        }
      })();
    },
    [executeRecaptcha]
  );

  return (
    <Layout dark>
      <div className="home-section-contact">
        <div className="container-80">
          <h2 className="f-w-600 text-center color-white">
            Get in touch with us
          </h2>
          <p className="contact-sub-heading">
            If you would like to learn more about our current solutions or future
            products, request a demo, inquire about our upcoming seed series
            funding, or have any other questions, please fill out the form below
            and we will get back to you quickly.
          </p>
          <form className="contact-form" onSubmit={onSubmit}>
            <div className="form-row">
              <input type="text" placeholder="Subject" required />
              <input type="email" placeholder="Email" required />
            </div>
            <textarea
              placeholder="Type your message here..."
              rows={4}
              required
            />
            <button type="submit" disabled={isDisabled} className="button button--dark">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

const Contact = () => {
  return (
    <>
      <RecaptchaComponent />
    </>
  );
};

export default Contact;
