import React, { useState, useEffect } from "react";
import { useRequest } from "ahooks";
import Layout from "../../layouts/Layout";
import BlogItem from "./BlogItem";
import Loading from "../loading";
import { getPosts } from "../../api";
import Seo from "../seo";

const Blog = () => {
  const [nextToken, setNextToken] = useState(null);
  const [posts, setPosts] = useState([]);

  const { data, loading } = useRequest(() =>
    getPosts({ nextToken, isPublished: true })
  );

  const fetchPosts = useRequest(getPosts, {
    manual: true,
  });
  
  useEffect(() => {
    if (!loading && data) {
      setPosts(data.items);
      setNextToken(data?.nextToken || null);
    }
  }, [loading]);

  useEffect(() => {
    if (!fetchPosts.loading) {
      if (fetchPosts.data?.items) {
        setPosts([...posts, ...fetchPosts.data?.items]);
        setNextToken(fetchPosts?.data.nextToken || null);
      }
    }
  }, [fetchPosts.loading]);

  const loadPosts = async () => {
    await fetchPosts.run({ nextToken });
  };
  return (
    <>
     <Seo title="Stacks Blog" description="Learn about Stacks, its founders, current solutions, and future products." url={window.location.href} />
      <Layout>
        <div className="blog-section">
          <div className="container-80">
            <h1>Stacks Blog</h1>

            {!loading && posts ? (
              <div className="blog-list">
                {posts.map((post) => (
                  <BlogItem post={post} />
                ))}
              </div>
            ) : (
              <Loading />
            )}

            {!loading && posts.length === 0 && (
              <div className="row row-center">No posts</div>
            )}

            {fetchPosts.loading ? (
              <Loading />
            ) : (
              nextToken && (
                <div className="row row-center">
                  <button onClick={() => loadPosts()} className="button">
                    Load more
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Blog;
