import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../../contexts/user";

const PrivatePage = ({ children }) => {
  const [user] = useUser();

  return user ? children : <Navigate to="/dashboard/login" />
};

export default PrivatePage;
