import React from "react";
import { Link } from "react-router-dom";
import Layout from "./Layout";
import Social from "../components/social";

const Footer = () => {
  return (
    <Layout dark>
      <div className="footer">
        <div className="container-80">
          <div className="footer__menu">
            <div className="footer-menu-item">
              <p className="footer-menu-item__title">Solutions</p>
              <Link to="/solutions/101-eligibility-analyzer">
                101 Eligibility Analyzer
              </Link>
              <Link to="/solutions/infringement-research">
                Infringement Research
              </Link>
              <Link to="/solutions/patent-clearance">
                Patent Clearance
              </Link>
              <Link to="/solutions/patent-lawyer-finder">
                Patent Lawyer Finder
              </Link>
            </div>

            <div className="footer-menu-item">
              <p className="footer-menu-item__title">Company</p>
              <Link to="/about">About Us</Link>
              <Link to="/blog">Blog</Link>
            </div>

            <div className="footer-menu-item">
              <p className="footer-menu-item__title">Resources</p>
              <Link to="/contact">Contact</Link>
              <Link to="/developer">For Developers</Link>
            </div>
          </div>
          <div className="footer__text">
            <Social
              linkedin="https://www.linkedin.com/company/stacks-tech"
              twitter="https://twitter.com/@stacks_ai"
              facebook="https://www.facebook.com/Stacks-105819691229112"
              className="social-footer__left mb-20"
            />
            <div>
              <p>
                Copyright © {new Date().getFullYear()} Stacks. All rights
                reserved.
              </p>
              <div className="footer-links">
                <Link to="/privacy">Privacy Policy</Link> |
                <Link to="/terms">Terms of Service</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Footer;
