import React from "react";

const Layout = ({ children, dark, darker, full }) => {
  const className = `layout ${dark ? "layout__dark" : ""} ${
    darker ? "layout__darker" : ""
  } ${full ? "layout__full" : ""}`;

  return <div className={className}>{children}</div>;
};

export default Layout;
