import React from "react";
import Layout from "../../layouts/Layout";
import Contact from "../home/Contact";
import GetStarted from "../getStarted/GetStarted";
import MotherboardIcon from "../../assets/Motherboard.png";
import SearchPropertyIcon from "../../assets/SearchProperty.png";
import ShowPropertyIcon from "../../assets/ShowProperty.png";
import BrainstormSkillIcon from "../../assets/BrainstormSkill.png";

import Seo from "../seo";

const Solutions = () => {
  return (
    <>
      <Seo title="Patent Clearance - Stacks" description="Experience a global patent clearance to identify and screen relevant patents that may impact your product." url={window.location.href} />
      <Layout>
        <div className="solutions-section">
          <div className="container-80">
            <h1 className="service-h1">Patent Clearance</h1>

            <div data-aos="fade-up" className="feature">
              <img src={BrainstormSkillIcon} alt="Brainstorm important questions regarding competitors and pending apploications" />
              <h4>
              Do you know if any of your competitors have patents or pending patent applications that may affect your product? Does the idea or the product you are investing your time or money has the freedom to operate?
              </h4>
            </div>
            <div data-aos="fade-up" className="feature">
              <img src={SearchPropertyIcon} alt="Fail to find relevant patents" />
            <h4>
            Is it possible that a relevant patent is out there, but you fail to find it because of different terminology and language?
            </h4>
            </div>
            <div data-aos="fade-up" className="feature">
              <img src={ShowPropertyIcon} alt="Search property" />
              <h4>
              Enjoy a global patent clearance experience that requires no training by leveraging our unique Infringement Research solution. Simply describe your product or idea in natural language and get the most relevant patents.
              </h4>
            </div>
            <div data-aos="fade-up" className="feature">
              <img src={MotherboardIcon} alt="Technical advantage" />
              <h4>
              Our proprietary, custom-made, and cloud-based AI tool understands the patent language and its underlying technology regardless of terminology and changes in time.
              </h4>
            </div>
          </div>

          <GetStarted tryNowUrl={process.env.REACT_APP_CLEARANCE_URL}/>
        </div>
      </Layout>
      <Contact />
    </>
  );
};

export default Solutions;
