import React, { useState, useEffect } from "react";
import { useRequest } from "ahooks";
import { getNoteApi } from "../../api";
import Layout from "../../layouts/Layout";
import Loading from "../loading";
import GetStarted from "../getStarted/GetStarted";
import Social from "../social";
import StacksTeamwork from "../../assets/stacks-teamwork.webp";
import { Storage } from "aws-amplify";
import Seo from "../seo";

const About = () => {
  const { data, loading } = useRequest(() => getNoteApi("team"));
  const [signedUrls, setSignedUrls] = useState(null);

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  useEffect(() => {  
    const fetchImages = async () => {
      const members = JSON.parse(data.content || "[]");
  
      const urls = [];
  
      await asyncForEach(members, async (member) => {
        const url = await Storage.get(member.image);
        urls.push(url);
      });
  
      setSignedUrls(urls);
    };
    if (!loading && data) {
      fetchImages();
    }
  }, [loading]);
  
  return (
    <>
      <Seo title="About Stacks: The Only AI Solution for Patent Eligibility under the Alice Test - Stacks" description="If you want to act proactively and get actionable insights on the risks your patent portfolio may have, you are at the right place - with the right people." url={window.location.href} />
      <Layout>
        <div className="about-section">
          <div className="container-80">
            <h1 className="text-center">We are Stacks</h1>

            <div className="about-hero">
              <img data-aos="zoom-in-right" alt="" src={StacksTeamwork} />
              <div data-aos="zoom-in-left" className="about-hero-text">
                <p>
                  A team of engineers, physicists, and passionate believers in
                  data, mathematics, and machine learning. We thrive to build
                  data-driven solutions for hard problems in business and law.
                </p>

                <h4>Our mission</h4>

                <p>
                  To provide business intelligence and actionable insights to IP
                  owners and other stakeholders using data and the latest
                  advances in analytics.
                </p>

                <h4>What we do</h4>

                <p>
                  We dig and label stacks of data coming from host places such
                  as external APIs, public libraries, court websites, internal
                  databases, search engines, etc.
                </p>

                <p>
                  The labeled data is used for training models that can
                  determine the contextual, linguistic, and technological
                  similarities between different inputs.
                </p>

                <p>
                  Our trained models become the main engine for legal and patent
                  research tools available as Stacks API. Law firms, corporate
                  legal departments, and financial institutions can use Stacks
                  API to address legal authority issues, patent eligibility, and
                  infringement risks.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Layout dark>
        <div className="about-section">
          <div className="container-80">
            <h1 className="color-white">Our Team</h1>
            {loading ? (
              <Loading />
            ) : (
              <div className="team-wrapper">
                {JSON.parse(data.content || "[]").map((member, i) => (
                  <div data-aos="fade-up" className="team">
                    {signedUrls ? (
                      <img
                        loading="lazy"
                        className="team-img"
                        alt="Teammate"
                        src={signedUrls[i]}
                      />
                    ) : (
                      <Loading />
                    )}
                    <p className="team-name">{member.name}</p>
                    <p className="team-title">{member.title}</p>
                    <Social
                      website={member.social.website}
                      linkedin={member.social.linkedin}
                      email={member.social.email}
                      className="mt-10"
                      fill="#fff"
                      strokeFill="#2b2e30"
                      team
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Layout>
      <GetStarted />
    </>
  );
};

export default About;
