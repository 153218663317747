import React, { useState, useEffect } from "react";
import { useRequest } from "ahooks";
import { Link } from "react-router-dom";
import Loading from "../loading";
import { getLatestPosts } from "../../api";
import Slider from "react-slick";
import { Spin } from "antd";
import { useWindow } from "../../hooks";
import { Storage } from "aws-amplify";

const Item = ({ post }) => {
  const [signedUrl, setSignedUrl] = useState("");

  const fetchImage = async () => {
    const url = await Storage.get(post.image);
    setSignedUrl(url);
  };

  const getDate = () => {
    const date = new Date(post.createdAt);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return [month, day, year].join(".");
  };

  useEffect(() => {
    fetchImage();
    return () => {
      setSignedUrl("");
    };
  }, []);


  return (
    <div className="item-container">
      <Link to={`/blog/${post.id || ""}`}>
        <div className="item">
          <div className="image">
            {signedUrl ? (
              <img src={signedUrl} alt="post" loading="lazy" />
            ) : (
              <Spin />
            )}
          </div>
          <div className="info">
            <p className="blog-item-date">{getDate()}</p>
            <h4>{post.title}</h4>
            <p className="blog-item-author"><strong>By {post?.author}</strong></p>
          </div>
        </div>
      </Link>
    </div>
  );
};

const NextArrow = (props) => {
  const { style, onClick } = props;
  return (
    <div
      className="carousel-arrow"
      style={{
        ...style,
        right: -15,
      }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 5l7 7-7 7"
        />
      </svg>
    </div>
  );
};

const PrevArrow = (props) => {
  const { style, onClick } = props;
  return (
    <div
      className="carousel-arrow"
      style={{ ...style, left: -15 }} // should be -25
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </div>
  );
};

const Blog = ({ id }) => {
  const [width] = useWindow();

  const { data, loading } = useRequest(() => getLatestPosts({ id }));

  const slidesCount = () => {
    if (width < 481) {
      return 1;
    } else if (width < 769) {
      if (data && data.length < 2) return data.length;
      return 2;
    } else if (width < 1140) {
      if (data && data.length < 3) return data.length;
      return 3;
    } else {
      if (data && data.length < 4) return data.length;
      return 4;
    }
  };

  const settings = {
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: slidesCount(),
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: function (index) {
    },
  };

  return (
    <div className="latest-posts mt-50 mb-20">
      {!loading && data ? (
        <>
          {data.length > 0 && (
            <>
              <h2 className="mt-30">Also on Stacks</h2>
              <Slider {...settings}>
                {data.map((post) => (
                  <Item post={post}/>
                ))}
              </Slider>
            </>
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Blog;
