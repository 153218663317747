import React from "react";
import { Link } from "react-router-dom";
import Seo from "../seo";
import Layout from "../../layouts/Layout";
import Contact from "./Contact";
import Terminal from "../developer/Terminal";
import GetStarted from "../getStarted/GetStarted";
import InfringementResearch from "./InfringementResearch";
import AliceTest from "./AliceTest";
import TopBanner from "./TopBanner";
import Buttons from "../../components/getstartedButtons"


const Home = () => {

  return (
    <>
      <Seo
        title="Stacks: Eliminate Patent Eligibility and Infringement Concerns"
        description="Stacks offers its unique AI-powered solutions to help you get actionable insights into the risks your patent portfolio may be subject to."
        url={window.location.href}
        twitterCardType="summary_large_image"
        social="true"
      />
      <Layout full="true">
        <div className="section-home-hero">
          <div className="container-80">
            <div className="home-hero-text">
              <div className="home-hero-h1-wrapper">
                <h1 className="home-hero-h1 color-blue">
                  We dig and label stacks of documents
                </h1>
              </div>
              <div className="home-hero-h1-wrapper mt-10">
                <h2 className="home-hero-h1 color-blue">
                  to find <span>actionable</span>
                </h2>
              </div>
              <div className="home-hero-h1-wrapper multiple mt-10">
                <h2 className="home-hero-h1 color-blue">
                  <span>legal</span>,
                </h2>
                <h2 className="home-hero-h1">
                  <span>scientific</span>,
                </h2>
              </div>
              <div className="home-hero-h1-wrapper mt-10">
                <h2 className="home-hero-h1 color-blue">
                  and <span>technological</span>
                </h2>
              </div>
              <div className="home-hero-h1-wrapper mt-10">
                <h2 className="home-hero-h1 color-blue">
                  <span>insights</span>.
                </h2>
              </div>
              <div className="home-hero-h2-wrapper">
                <h4 className="home-hero-h1 home-hero-p color-dark-grey">
                  Stacks helps companies, financial institutions, law firms,
                  and IP owners to address patent eligibility and infringement
                  risks.
                </h4>
              </div>
              <div className="home-hero-h2-wrapper multiple">
              <Buttons tryNowUrl={null} />
              </div>
            </div>

            <TopBanner />
          </div>
        </div>

        <div className="home-section-service">
          <h1>Our solutions turn data into knowledge.</h1>
          <svg
            viewBox="0 0 140 140"
            width="120"
            height="120"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="#121313">
              <path
                strokeWidth="5"
                d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"
                fill="#121313"
              />
            </g>
          </svg>
        </div>
        <AliceTest />
        <div className="home-section-divider">
          <div className="container-80">
            <div className="divider" />
          </div>
        </div>
        <InfringementResearch />
      </Layout>
      <Layout dark>
        <div className="home-section-developer">
          <div className="container-80">
            <div data-aos="zoom-in" className="developer-first-text">
              <div>
              <h2>Developer first</h2>

              <p>Get started in minutes with an API key</p>

              <ul>
                <li>
                  Get a hold of infringement risks associated with your patent
                  portfolio.
                </li>
                <li>
                  Get a quick assesment of the subject matter eligibility of
                  your claims.
                </li>
              </ul>

              <Link to="/developer">
                <button className="button button--dark">
                  View documentation
                </button>
              </Link>
              </div>
            </div>
            <Terminal />
          </div>
        </div>
      </Layout>

      <GetStarted />

      <Contact />
    </>
  );
};

export default Home;
