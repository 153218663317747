import React from "react";

const Buttons = ({tryNowUrl = null}) => {
  return (
    <>
    <div className="flex-multiple">
          <a
            className="button"
            href={tryNowUrl ? tryNowUrl: process.env.REACT_APP_SOLUTIONS_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="try-now">Try now</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#f4f4f4"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z" />
            </svg>
          </a>
          <a
            className="button"
            href={process.env.REACT_APP_CALENDLY_URL}
            target="_self"
            rel="noopener noreferrer"
          >
            <span className="book-demo">Book a Demo</span>
          </a>
        </div>
        </>
  );
};

export default Buttons;
