import React from "react";

const FacebookIcon = ({ fill }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z"></path>
  </svg>
);

const Linkedin = ({ fill }) => (
  <svg
    viewBox="0 0 30 30"
    width="30"
    height="30"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z"></path>
  </svg>
);

const MailIcon = ({ fill, strokeFill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill={fill}
    viewBox="0 0 24 23"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
      fill={strokeFill}
    />
  </svg>
);

const GlobeIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5"
    viewBox="0 0 20 20"
    fill={fill}
  >
    <path
      fillRule="evenodd"
      d="M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z"
      clipRule="evenodd"
    />
  </svg>
);

const Social = ({
  className,
  team,
  website,
  linkedin,
  fill,
  strokeFill,
  email,
  facebook,
}) => {
  return (
    <div className={`social ${className || ""}`}>
      {team ? (
        <>
          <a href={website} target="_blank" rel="noopener noreferrer">
            <GlobeIcon fill={fill || "#fff"} />
          </a>
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            <Linkedin fill={fill || "#fff"} />
          </a>
          <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
            <MailIcon fill={fill || "#fff"} strokeFill={strokeFill || "#fff"} />
          </a>
        </>
      ) : (
        <>
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            <Linkedin fill={fill || "#fff"} />
          </a>
          <a href={facebook} target="_blank" rel="noopener noreferrer">
            <FacebookIcon fill={fill || "#fff"} />
          </a>
        </>
      )}
    </div>
  );
};

export default Social;
