import React, { useState, useEffect, useRef } from "react";
import { Storage } from "aws-amplify";
import { useNavigate, useParams } from "react-router-dom";
import { useRequest } from "ahooks";
import Layout from "../../layouts/Layout";
import { getPostApi, updatePostApi, deletePostApi } from "../../api";
import { toast } from "react-toastify";
import { Popconfirm } from "antd";
import Loading from "../loading";
import { Upload, Modal, message, Switch } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import MDEditor from "@uiw/react-md-editor";

const EditPost = () => {
  const [value, setValue] = useState("");
  const [isPublished, setPublished] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const inputRef = useRef(null);

  const [uploadState, setUploadState] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  });

  const { data, loading: postLoading } = useRequest(() => getPostApi(id), {
    ready: id,
  });

  const removeImage = () => {
    const preview = document.getElementById("post-image");
    preview.src = "";
    setImage(null);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    toast.dark("Loading!");

    setIsSaving(true);

    setTimeout(async () => {
      const title = e.target[0].value;
      const author = e.target[1].value;
      const caption = e.target[2].value;
      const description = e.target[3].value;
      const content = value;

      if (!image) {
        toast.error("Please upload image!");
        return;
      }

      const url = typeof image === "string" && image;
      const imgKey = url ? data.image : await uploadImage();

      const input = {
        id: data.id,
        title,
        content,
        caption: caption || "",
        author,
        image: imgKey,
        images: uploadState.fileList.map((file) => file.imgKey),
        description,
        isPublished: isPublished ? 1 : 0,
        updatedAt: new Date().toISOString(),
      };

      const isUpdated = await updatePostApi(input);

      if (isUpdated) toast.dark("Post updated successfully");
    }, 300);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        Upload <br /> image
      </div>
    </div>
  );

  const handleCancel = () => {
    setUploadState({ ...uploadState, previewVisible: false });
  };

  const previewChange = (e) => {
    const preview = document.getElementById("post-image");
    const image = e.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = async () => {
      preview.src = reader.result + "";
      if (image.type === "image/jpeg" || image.type === "image/png") {
        setImage(image);
      } else {
        toast.error("Please upload jpeg or png!");
      }
    };
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const customRequest = (props) => {
    const { file, onSuccess } = props;

    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setUploadState({
      ...uploadState,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const handleChange = async ({ fileList, file: currentFile }) => {
    const file = fileList[fileList.length - 1];

    if (!file) {
      setUploadState({
        ...uploadState,
        fileList,
      });
      return;
    }

    if (currentFile.status === "removed") {
      setUploadState({
        ...uploadState,
        fileList,
      });
      return;
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG or PNG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    if (!isJpgOrPng || !isLt2M) return;

    setUploadState({
      ...uploadState,
      fileList: [...fileList.slice(0, -1), { status: "uploading", percent: 0 }],
    });

    const originFile = file.originFileObj;

    const fileName = `${Date.now()}-${originFile.name}`;

    const res = await Storage.put(fileName, originFile, {
      contentType: originFile.type,
    });

    setUploadState({
      ...uploadState,
      fileList: [
        ...fileList.slice(0, -1),
        { ...file, imgKey: res.key, status: "done" },
      ],
    });
  };

  const copyUrl = async (img) => {
    await navigator.clipboard.writeText(img.imgKey);
    toast.dark("image URL copied");
  };

  const deletePost = async () => {
    toast.dark("Loading!");
    await deletePostApi(id);
    toast.dark("Post deleted successfully");
    Storage.remove(data.image);
    data?.images?.forEach((image) => Storage.remove(image));
    navigate("/dashboard/blog");
  };

  const fetchImages = async () => {
    const interval = setInterval(() => {
      const markdownElement = document.querySelector(".markdown-container");

      if (!markdownElement) return;

      const images = markdownElement.querySelectorAll("img");

      if (isSaving) {
        images?.forEach((img) => {
          const imgKey = img.getAttribute("img-key");
          img.src = imgKey;
        });
        clearInterval(interval);
      }

      images?.forEach((img) => {
        img.setAttribute("loading", "lazy");
        const imgKey = img.getAttribute("img-key");
        const imgSrc = img.getAttribute("src");

        if (!imgKey) {
          Storage.get(imgSrc).then((signedUri) => {
            fetch(signedUri).then((data) => {
              if (data.status === 200) {
                img.src = signedUri;
                img.setAttribute("img-key", imgKey);
              }
            });
          });
        }
      });
    }, 200);
  };

  const uploadImage = async () => {
    const fileName = `${Date.now()}-${image.name}`;
    const res = await Storage.put(fileName, image, {
      contentType: image.type,
    });
    return res.key;
  };

  const fetchImage = async () => {
    const url = await Storage.get(data.image);
    setImage(url);
  };

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const fetchPostImages = async (images) => {
    const files = [];

    await asyncForEach(images, async (imgKey) => {
      const url = await Storage.get(imgKey);
      const res = await fetch(url);
      const data = await res.blob();
      const file = new File([data], imgKey, { type: data.type });
      const thumbUrl = url;

      const fileObj = {
        imgKey,
        originFileObj: file,
        status: "done",
        thumbUrl,
        name: file.name,
        lastModified: file.lastModified,
        size: file.size,
        type: file.type,
        webkitRelativePath: "",
      };
      files.push(fileObj);
    });

    setUploadState({
      ...uploadState,
      fileList: files,
    });

    setLoading(false);
  };

  const getDate = () => {
    const date = new Date(data.updatedAt);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return [month, day, year].join(".");
  };

  useEffect(() => {
    fetchImages();
  }, []);

  useEffect(() => {
    if (!postLoading && data) {
      setPublished(data.isPublished === 1 ? true : false);
      fetchImage();
      setValue(data.content);
      fetchPostImages(data.images || []);
    }
  }, [postLoading]);

  return (
    <Layout>
      <div className="blog-section">
        <div className="container-80">
          {loading ? (
            <Loading />
          ) : (
            <>
              <h1>Edit Post</h1>

              <form onSubmit={onSubmit}>
                <p className="form-title">Title</p>
                <input
                  className="dark"
                  type="text"
                  placeholder="Title"
                  defaultValue={data.title}
                  required
                />

                <p className="form-title mt-10">Author</p>
                <input
                  className="dark"
                  type="text"
                  placeholder="Author"
                  defaultValue={data?.author}
                  required
                />

                <p className="form-title mt-10">Caption</p>
                <input
                  className="dark"
                  type="text"
                  placeholder="Caption"
                  defaultValue={data?.caption}
                />

                <p className="form-title mt-10">Description</p>
                <textarea
                  className="dark"
                  placeholder="Post description..."
                  rows={4}
                  defaultValue={data.description}
                  required
                />

                <p className="form-title mt-10">Content</p>
                <div className="markdown-container">
                  <MDEditor value={value} onChange={setValue} height={500} />
                  <MDEditor.Markdown source={value} className="mt-30" />
                </div>

                <div className="upload-wrapper">
                  <input
                    ref={inputRef}
                    className="hide"
                    type="file"
                    onChange={previewChange}
                  />

                  {image && (
                    <button
                      onClick={removeImage}
                      className="button button--white"
                      type="button"
                    >
                      Remove featured image
                    </button>
                  )}

                  {!image && (
                    <button
                      onClick={() => inputRef.current.click()}
                      className="button button--white"
                      type="button"
                    >
                      Upload image
                    </button>
                  )}

                  <img
                    src={(typeof image === "string" && image) || ""}
                    loading="lazy"
                    alt=""
                    id="post-image"
                  />
                </div>

                <Upload
                  customRequest={customRequest}
                  listType="picture-card"
                  fileList={uploadState.fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  itemRender={(item, img) => {
                    return img.status === "uploading" ? (
                      <Loading />
                    ) : (
                      <>
                        {item}
                        <p onClick={() => copyUrl(img)} className="ant-copy">
                          copy
                        </p>
                      </>
                    );
                  }}
                >
                  {uploadState.fileList.length >= 8 ? null : uploadButton}
                </Upload>

                <Switch
                  className="mt-50"
                  checkedChildren="Live"
                  unCheckedChildren="Draft"
                  defaultChecked={isPublished}
                  onChange={(value) => setPublished(value)}
                />

                <br />

                <div className="edit-post-actions">
                  <button type="submit" className="button mt-30">
                    Update Post
                  </button>
                  <Popconfirm
                    title="Are you sure your want to delete this post?"
                    onConfirm={deletePost}
                    okText="Yes"
                    cancelText="No"
                    placement="topRight"
                  >
                    <button
                      type="button"
                      className="button button--danger mt-30"
                    >
                      Delete
                    </button>
                  </Popconfirm>
                </div>
              </form>

              <p className="f-w-300">Last updated at: {getDate()}</p>

              <Modal
                visible={uploadState.previewVisible}
                title={uploadState.previewTitle}
                footer={null}
                centered
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={uploadState.previewImage}
                />
              </Modal>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EditPost;
