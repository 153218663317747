import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import { useRequest } from "ahooks";
import { useNavigate } from "react-router-dom";
import Layout from "../../layouts/Layout";
import { createNoteApi, getNoteApi, updateNoteApi } from "../../api";
import { toast } from "react-toastify";
import Loading from "../loading";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, Modal, message } from "antd";

const Team = () => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { data, loading: noteLoading } = useRequest(() => getNoteApi("team"));

  const [uploadState, setUploadState] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    const content = JSON.stringify(members);

    toast.dark("Loading!");

    const isUpdated = await updateNoteApi({
      id: "team",
      content,
      images: uploadState.fileList.map((file) => file.imgKey),
    });

    if (isUpdated) {
      toast.dark("Team information updated successfully");
      navigate("/dashboard");
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>
        Upload <br /> image
      </div>
    </div>
  );

  const handleCancel = () => {
    setUploadState({ ...uploadState, previewVisible: false });
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const customRequest = (props) => {
    const { file, onSuccess } = props;

    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setUploadState({
      ...uploadState,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const handleChange = async ({ fileList, file: currentFile }) => {
    const file = fileList[fileList.length - 1];

    if (!file) {
      setUploadState({
        ...uploadState,
        fileList,
      });
      return;
    }

    if (currentFile.status === "removed") {
      setUploadState({
        ...uploadState,
        fileList,
      });
      return;
    }

    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG or PNG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    if (!isJpgOrPng || !isLt2M) return;

    setUploadState({
      ...uploadState,
      fileList: [...fileList.slice(0, -1), { status: "uploading", percent: 0 }],
    });

    const originFile = file.originFileObj;

    const fileName = `${Date.now()}-${originFile.name}`;

    const res = await Storage.put(fileName, originFile, {
      contentType: originFile.type,
    });

    setUploadState({
      ...uploadState,
      fileList: [
        ...fileList.slice(0, -1),
        { ...file, imgKey: res.key, status: "done" },
      ],
    });
  };

  const copyUrl = async (img) => {
    await navigator.clipboard.writeText(img.imgKey);
    toast.dark("image URL copied");
  };

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const fetchPostImages = async (images) => {
    const files = [];

    await asyncForEach(images, async (imgKey) => {
      const url = await Storage.get(imgKey);
      const res = await fetch(url);
      const data = await res.blob();
      const file = new File([data], imgKey, { type: data.type });
      const thumbUrl = url;

      const fileObj = {
        imgKey,
        originFileObj: file,
        status: "done",
        thumbUrl,
        name: file.name,
        lastModified: file.lastModified,
        size: file.size,
        type: file.type,
        webkitRelativePath: "",
      };
      files.push(fileObj);
    });

    setUploadState({
      ...uploadState,
      fileList: files,
    });

    setLoading(false);
  };

  useEffect(() => {
    if (!noteLoading && data) {
      setMembers(JSON.parse(data.content || "[]"));
      fetchPostImages(data.images || []);
    } else if (!noteLoading && !data) {
      createNoteApi("team");
    }
  }, [noteLoading]);

  return (
    <Layout>
      <div className="blog-section">
        <div className="container-80">
          <h1>Team information Editor</h1>

          {loading ? (
            <Loading />
          ) : (
            <>
              <form onSubmit={onSubmit}>
                {members.map((member, i) => (
                  <div className="member">
                    <textarea
                      spellCheck={false}
                      onChange={(e) => {
                        const temp = [...members];
                        temp[i] = JSON.parse(e.target.value);
                        setMembers(temp);
                      }}
                      row={8}
                      value={JSON.stringify(member, undefined, 4)}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      onClick={() => {
                        setMembers([...members.filter((_, idx) => idx !== i)]);
                      }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                ))}

                <div className="row row-center">
                  <button
                    onClick={() =>
                      setMembers([
                        ...members,
                        {
                          name: "",
                          title: "",
                          image: "",
                          social: {
                            website: "",
                            linkedin: "",
                            email: "",
                          },
                        },
                      ])
                    }
                    type="button"
                    className="button"
                  >
                    Add member
                  </button>
                </div>

                <Upload
                  customRequest={customRequest}
                  listType="picture-card"
                  fileList={uploadState.fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  itemRender={(item, img) => {
                    return img.status === "uploading" ? (
                      <Loading />
                    ) : (
                      <>
                        {item}
                        <p onClick={() => copyUrl(img)} className="ant-copy">
                          copy
                        </p>
                      </>
                    );
                  }}
                >
                  {uploadState.fileList.length >= 8 ? null : uploadButton}
                </Upload>

                <button type="submit" className="button mt-50 mb-20">
                  Update
                </button>

                <Modal
                  visible={uploadState.previewVisible}
                  title={uploadState.previewTitle}
                  footer={null}
                  centered
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={uploadState.previewImage}
                  />
                </Modal>
              </form>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Team;
