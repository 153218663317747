import React from "react";
import { useRequest } from "ahooks";
import { getNoteApi } from "../../api";
import Layout from "../../layouts/Layout";
import Loading from "../loading";
import MDEditor from "@uiw/react-md-editor";
import Seo from "../seo";

const Terms = () => {
  const { data, loading } = useRequest(() => getNoteApi("terms-of-service"));

  const getDate = () => {
    const date = new Date(data.updatedAt);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const hour = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return (
      [month, day, year].join(".") + ", " + [hour, minutes, seconds].join(":")
    );
  };

  return (
    <>
    <Seo title="Terms - Stacks" url={window.location.href} />
    <Layout>
      <div className="privacy-policy">
        <div className="container-80">
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className="markdown-container">
                <MDEditor.Markdown source={data?.content} className="mt-30" />
              </div>
              <p className="text-center mt-10">Last updated at {getDate()}</p>
            </>
          )}
        </div>
      </div>
    </Layout>
    </>
  );
};

export default Terms;
