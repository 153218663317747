/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:3d71b72e-3669-4f34-910c-4472749fa701",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_nHakfLAj2",
    "aws_user_pools_web_client_id": "1f92t0jgk2g3r91ie722be5ngt",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://d5kyh5c3mbaczp6h2deoytyejy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ksbyvuqpgbbslb6f6unkw5lr5q",
    "aws_cloud_logic_custom": [
        {
            "name": "apiKey",
            "endpoint": "https://46f8bjfrx0.execute-api.us-east-1.amazonaws.com/devel",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "stacksreact8c9dc07446ad4e54807a5b42d3eeea3610628-devel",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "apiKeyTable-devel",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
