import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Storage } from "aws-amplify";
import Loading from "../loading";
import { Image } from "antd";

const BlogItem = ({ post }) => {
  const [signedUrl, setSignedUrl] = useState(null);

  const fetchImage = async () => {
    const url = await Storage.get(post.image);
    setSignedUrl(url);
  };

  const getDate = () => {
    const date = new Date(post.createdAt);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return [month, day, year].join(".");
  };

  useEffect(() => {
    fetchImage();
  }, []);

  return (
    <div className="blog-list-item">
      <Link to={`/blog/${post.id || ""}`} className="blog-list-link">
        {signedUrl ? (
          <Image
            preview={false}
            src={signedUrl}
            loading="lazy"
            placeholder={<div className="img-placeholder"></div>}
          />
        ) : (
          <Loading />
        )}
        <p className="blog-item-date">{getDate()}</p>
        <h4>{post.title}</h4> 
        <p className="blog-item-author">By {post?.author}</p>
      </Link>
    </div>
  );
};

export default BlogItem;
