import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../logo.png";
import { logout } from "../api";
import { useUser } from "../contexts";

const Navbar = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [user, dispatchUser] = useUser();

  const isAdmin = location.pathname.startsWith("/dashboard");

  const openModal = () => {
    const modal = document.getElementById("modal-btn");
    modal.checked = true;
  };

  const hideNav = (e) => {
    if (
      e.target.classList[0] === "nav__link" ||
      e.target.classList[0] === "navbar-dropdown__item" ||
      e.target.classList[0] === "navbar-link"
    ) {
      const nav = document.getElementById("nav-toggle");
      nav.checked = false;
    }
  };

  useEffect(() => {
    const onScroll = (e) => {
      const navHeight = 90; // 50 + (20 + 20 margin)
      const scrolled = e.target.documentElement.scrollTop;

      if (scrollTop === 0) {
        setScrollTop(scrolled);
        return;
      }

      const navbar = document.querySelector(".navbar");

      if (scrolled > navHeight) {
        navbar?.classList.add("animate");
      } else {
        navbar?.classList.remove("animate");
      }

      if (scrolled > scrollTop) {
        navbar.classList.remove("sticky");
      } else {
        navbar.classList.add("sticky");
      }

      setScrollTop(scrolled);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <div className="navbar">
      <Link
        to="/"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <img alt="stacks-logo" className="navbar__logo" src={Logo} />
      </Link>

      {isAdmin ? (
        <>
          {user && (
            <>
              <nav className="navbar-menu w-auto">
                <div className="navbar-item">
                  <Link to="/dashboard" className="navbar-link">
                    Dashboard
                  </Link>
                </div>
                <div className="navbar-item">
                  <Link to="/dashboard/team" className="navbar-link">
                    Team
                  </Link>
                </div>
                <div className="navbar-item">
                  <Link to="/dashboard/blog" className="navbar-link">
                    Blog
                  </Link>
                </div>
                <div className="navbar-item">
                  <Link to="/dashboard/privacy" className="navbar-link">
                    Privacy
                  </Link>
                </div>
                <div className="navbar-item">
                  <Link to="/dashboard/terms" className="navbar-link">
                    Terms
                  </Link>
                </div>
              </nav>

              <div
                onClick={() => {
                  logout();
                  dispatchUser({ type: "SIGN_OUT" });
                  navigate("/");
                }}
                className="button request-api hide-md"
              >
                Logout
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <nav className="navbar-menu">
            <div className="navbar-item">
              <Link to="/developer" className="navbar-link">
                Developer
              </Link>
            </div>
            <div className="navbar-item">
              <Link className="navbar-link is-dropdown" to="#">
                Solutions
                <svg
                  className="dropdown-arrow"
                  viewBox="0 0 140 140"
                  width="15"
                  height="15"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#121313">
                    <path
                      strokeWidth="5"
                      d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"
                      fill="#121313"
                    />
                  </g>
                </svg>
              </Link>
              <div className="navbar-dropdown">
                <Link
                  to="/solutions/101-eligibility-analyzer"
                  className="navbar-dropdown__item"
                >
                  101 Eligibility Analyzer
                </Link>
                <Link
                  to="/solutions/infringement-research"
                  className="navbar-dropdown__item"
                >
                  Infringement Research
                </Link>
                <Link
                  to="/solutions/patent-clearance"
                  className="navbar-dropdown__item"
                >
                  Patent Clearance
                </Link>
                <Link
                  to="/solutions/patent-lawyer-finder"
                  className="navbar-dropdown__item"
                >
                  Patent Lawyer Finder
                </Link>
              </div>
            </div>
            <div className="navbar-item">
              <Link to="/blog" className="navbar-link">
                Blog
              </Link>
            </div>
            <div className="navbar-item">
              <Link to="/about" className="navbar-link">
                About
              </Link>
            </div>
            <div className="navbar-item">
              <Link to="/contact" className="navbar-link">
                Contact
              </Link>
            </div>
          </nav>
          <button
                    onClick={()=>{
                      const newWindow = window.open(process.env.REACT_APP_CALENDLY_URL, '_self', 'noopener,noreferrer');
                      if (newWindow) newWindow.opener = null;
                    }
                  }
                    className="button book-demo hide-md"
                    >
                    Book a Demo
          </button>
        </>
      )}

      <input
        type="checkbox"
        name=""
        id="nav-toggle"
        className="navbar__checkbox"
      />
      <label htmlFor="nav-toggle" className="navbar__button show-md">
        <span className="navbar__icon">&nbsp;</span>
      </label>
      <div className="navbar__overlay show-md">
        {isAdmin ? (
          <nav onClick={hideNav} className="nav">
            <Link to="/" className="nav__link">
              Home
            </Link>
            {user && (
              <>
                <Link to="/dashboard" className="nav__link">
                  Dashboard
                </Link>
                <Link to="/dashboard/team" className="nav__link">
                  Team
                </Link>
                <Link to="/dashboard/blog" className="nav__link">
                  Blog
                </Link>
                <Link to="/dashboard/privacy" className="nav__link">
                  Privacy
                </Link>
                <Link to="/dashboard/terms" className="nav__link">
                  Terms
                </Link>

                <div className="divider--dark" />

                <div
                  onClick={() => {
                    logout();
                    dispatchUser({ type: "SIGN_OUT" });
                    navigate("/");
                  }}
                  className="nav__link"
                >
                  Logout
                </div>
              </>
            )}
          </nav>
        ) : (
          <nav onClick={hideNav} className="nav">
            <div className="navbar-item">
              <Link to="/" className="navbar-link">
                Home
              </Link>
            </div>
            <div className="navbar-item">
              <Link to="/developer" className="navbar-link">
                Developer
              </Link>
            </div>
            <div className="navbar-item">
              <Link className="navbar-link is-dropdown" to="#">
                Solutions
                <svg
                  className="dropdown-arrow"
                  viewBox="0 0 140 140"
                  width="20"
                  height="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="#f4f4f4">
                    <path
                      strokeWidth="5"
                      d="m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z"
                      fill="#f4f4f4"
                    />
                  </g>
                </svg>
              </Link>
              <div className="navbar-dropdown">
                <Link
                  to="/solutions/101-eligibility-analyzer"
                  className="navbar-dropdown__item"
                >
                  101 Eligibility Analyzer
                </Link>
                <Link
                  to="/solutions/infringement-research"
                  className="navbar-dropdown__item"
                >
                  Infringement Research
                </Link>
                <Link
                  to="/solutions/patent-clearance"
                  className="navbar-dropdown__item"
                >
                  Patent Clearance
                </Link>
                <Link
                  to="/solutions/patent-lawyer-finder"
                  className="navbar-dropdown__item"
                >
                  Patent Lawyer Finder
                </Link>
              </div>
            </div>
            <div className="navbar-item">
              <Link to="/blog" className="navbar-link">
                Blog
              </Link>
            </div>
            <div className="navbar-item">
              <Link to="/about" className="navbar-link">
                About
              </Link>
            </div>
            <div className="navbar-item">
              <Link to="/contact" className="navbar-link">
                Contact
              </Link>
            </div>

            <div className="divider--dark" />

            <div onClick={() => openModal()} className="nav__link">
              Request API
            </div>
          </nav>
        )}
      </div>
    </div>
  );
};

export default Navbar;
