import React from "react";
import { useRequest } from "ahooks";
import Layout from "../../layouts/Layout";
import Loading from "../loading";
import { Popconfirm, message, Table, Space } from "antd";
import { API } from "aws-amplify";

import {
  getMessages,
  getRequests,
  deleteRequestApi,
  deleteMessageApi,
  verifyRequestApi
} from "../../api";

message.config({
  duration: 2.5,
  maxCount: 3,
});

const appendKey = (data) => {
  data.map((d) => (d["key"] = data.indexOf(d)));
  return data;
};

const handleReqDelete = async (record, index) => {
  try {
    await deleteRequestApi(record.id);
    message.success(record.fullname + "'s request was deleted.");
    document
      .querySelector(`#requests-table`)
      .querySelectorAll(".ant-table-row")
      [index].classList.add("hide");
  } catch (e) {
    message.error(`Unsuccessful delete due to "${e}"!`);
  }
  return;
};

// verify the request api key
const handleVerifyRequest = async (record, index) => {

  try {
    await verifyRequestApi({ id: record.id, isVerified: true });
    message.success(
      record.fullname + "'s request was verified. The key was sent."
    );
    API.post("apiKey", "/verify_request", {
      body: {
        id: record.id,
        fullname: record.fullname,
        email: record.email,
      },
    });
    document
          .querySelector(`#requests-table`)
          .querySelectorAll(".ant-table-row")
          [index].classList.add("disabled-row");
  } catch (e) {
    console.log("Verify Error: ", e);
    message.error(`Unsuccessful verification due to "${e}"!`);
  }
  return;
};

const columnsRequests = [
  { title: "Full Name", dataIndex: "fullname", key: "fullname" },
  { title: "Company", dataIndex: "company", key: "company" },
  { title: "Email", dataIndex: "email", key: "email" },
  { title: "Referral Code", dataIndex: "referral", key: "referral" },
  { title: "Date/Time", dataIndex: "createdAt", key: "createdAt" },
  {
    title: "Action",
    dataIndex: "",
    key: "action",
    fixed: "right",
    render: (text, record, index) => (
      <Space size="middle">
        <Popconfirm
          title={"Are you sure you want to verify " + record.fullname + "'s request?"}
          onConfirm={async () => handleVerifyRequest(record, index)}
        >
          <button type="button" className="ant-btn">
            <span>Verify</span>
          </button>
        </Popconfirm>
        <Popconfirm
          title={"Are you sure you want to delete " + record.fullname + "'s request?"}
          onConfirm={async () => handleReqDelete(record, index)}
        >
          <button type="button" className="ant-btn">
            <span>Delete</span>
          </button>
        </Popconfirm>
      </Space>
    ),
  },
];

const columnsMessages = [
  { title: "Subject", dataIndex: "subject", key: "subject" },
  { title: "Email", dataIndex: "email", key: "email" },
  { title: "Date/Time", dataIndex: "createdAt", key: "createdAt" },

  {
    title: "Action",
    dataIndex: "",
    key: "action",
    fixed: "right",
    render: (text, record, index) => (
        <Popconfirm
          title={"Are you sure you want to delete " + record.email + "'s message?"}
          onConfirm={async () => handleMessageDelete(record, index)}
        >
          <button type="button" className="ant-btn">
            <span>Delete</span>
          </button>
        </Popconfirm>
    ),
  },
];

const handleMessageDelete = async (record, index) => {
  try {
    await deleteMessageApi(record.id);
    message.success(record.email + "'s message was deleted.");
    document
      .querySelector(`#messages-table`)
      .querySelectorAll(".ant-table-row")
      [index].classList.add("hide");
  } catch (e) {
    message.error(`Unsuccessful delete due to "${e}"!`);
  }
  return;
};

const Dashboard = () => {
  const messages = useRequest(() => getMessages());
  const requests = useRequest(() => getRequests());

  return (
    <Layout>
      <div className="dashboard-section">
        <div className="container-80">
          <h1>Dashboard</h1>
          <h2 className="mt-30">Latest Messages</h2>
          {!messages.loading && messages.data ? (
            <Table
              scroll={{ x: true }}
              id="messages-table"
              columns={columnsMessages}
              expandedRowRender={(record) => (
                <p style={{ margin: 0 }}>
                  Message: {record.message}
                </p>
              )}
              pagination={{ pageSize: 10 }}
              dataSource={appendKey(messages.data.items)}
            />
          ) : (
            <Loading />
          )}
          <h2 className="mt-30">Latest Requests</h2>
          {!requests.loading && requests.data ? (
            <Table
              scroll={{ x: true }}
              id="requests-table"
              columns={columnsRequests}
              expandedRowRender={(record) => (
                <p style={{ margin: 0 }}>
                  API Key: <strong>{record.id}</strong>
                </p>
              )}
              pagination={{ pageSize: 10 }}
              dataSource={appendKey(requests.data.items)}
            />
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
