import React, { useCallback } from "react";
import Layout from "../../layouts/Layout";
import { me, login } from "../../api";
import { useUser } from "../../contexts";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const RecaptchaComponent = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [, dispatchUser] = useUser();
  const navigate = useNavigate();

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!executeRecaptcha) return;
      (async () => {
        try {
          const email = e.target[0].value;
          const password = e.target[1].value;

          toast.dark("Loading!");

          if (await login({ email, password })) {
            dispatchUser({ type: "SIGN_IN", user: await me() });
            toast.dark("Successfully logged in");
            navigate("/dashboard");
          }
        } catch (e) {
          console.log(e);
        }
      })();
    },
    [executeRecaptcha]
  );

  return (
    <Layout>
      <div className="login">
        <div className="container-60">
          <h1>Login</h1>

          <form onSubmit={onSubmit}>
            <input type="email" className="dark" placeholder="Email" required />
            <input
              type="password"
              className="dark mt-10"
              placeholder="Password"
              required
            />

            <button type="submit" className="button mt-20">
              Login
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

const Login = () => {
  return (
    <>
      <RecaptchaComponent />
    </>
  );
};
export default Login;
