import React from "react";
import SwaggerUiIcon from "../../assets/swagger-ui.svg";
import { Link } from "react-router-dom";

const QuickStartGuide = () => {
  const openModal = () => {
    const modal = document.getElementById("modal-btn");
    modal.checked = true;
  };
  return (
    <div className="quick-start-guide">
      <h2 className="text-center">Quick Start Guide</h2>

      <div className="divider divider--white"></div>

      <div className="quick-start-guide__guide">
        <div className="wmde-markdown wmde-markdown-color">
          <h2 id="introduction">
            <a href="#introduction" aria-hidden="true" tabIndex="-1">
              <span className="icon icon-link"></span>
            </a>
            Introduction
          </h2>
          <p>
            The Stacks API is powered by machine learning models that can detect
            claims similar to the user's input text. There are two main
            solutions:
          </p>
          <table>
            <thead>
              <tr>
                <th>Service</th>
                <th>Endpoint</th>
                <th>Functionality</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Infringement Research</td>
                <td>
                  <a href="#v1infringement-researchpredict">
                    /v1/infringement-research/predict
                  </a>
                </td>
                <td>
                  Returns similar claims that the text is likely to infringe
                </td>
              </tr>
              <tr>
                <td>101 Eligibility Analyzer</td>
                <td>
                  <a href="#v1101-eligibilitypredict">
                    /v1/101-eligibility/predict
                  </a>
                </td>
                <td>
                  Tests a claim for eligibility under both "Alice Test Step
                  One" and "Alice Test Step Two" (read more{" "}
                    <Link to="/blog/the-alice-test-automated-a-fast-cost-effective-and-up-to-date-solution-for-a-fluid-and-ambiguous-law">
                      here
                    </Link>
                  )
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <strong>Version:</strong> 0.1.0
          </p>
          <h2 id="authorization">
            <a
              className="anchor"
              href="#authorization"
              aria-hidden="true"
              tabIndex="-1"
            >
              <svg
                className="octicon octicon-link"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z"
                ></path>
              </svg>
            </a>
            Authorization
          </h2>
          <p>
            The Stacks API is protected by an API key that you can <a role="button" tabIndex="0" onClick={()=>openModal()}>request</a> from
            us.
          </p>

          <table>
            <thead>
              <tr>
                <th>Security Scheme Type</th>
                <th>API Key</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Header parameter name</strong>
                </td>
                <td>
                  <strong>x-api-key</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            <strong>Note:</strong> We highly recommend using our{" "}
            <a href="https://api.stacksai.com/v1/docs">
              <span>Swagger UI</span>
              <img
                alt="swaggerui"
                src={SwaggerUiIcon}
                style={{
                  display: "inline-block",
                  width: "2rem",
                  margin: "0 3px",
                }}
              />
            </a>
            to directly authorize your access by entering the API key via the{" "}
            <code>Authorize</code> button and test the API endpoints.
          </p>
          <h2 id="v1infringement-researchpredict">
            <a
              className="anchor"
              href="#v1infringement-researchpredict"
              aria-hidden="true"
              tabIndex="-1"
            >
              <svg
                className="octicon octicon-link"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z"
                ></path>
              </svg>
            </a>
            /V1/INFRINGEMENT-RESEARCH/PREDICT
          </h2>
          <h2 id="get">
            <a className="anchor" href="#get" aria-hidden="true" tabIndex="-1">
              <svg
                className="octicon octicon-link"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z"
                ></path>
              </svg>
            </a>
            <em>
              <strong>GET</strong>
            </em>
          </h2>
          <p>
            <a href="https://api.stacksai.com/v1/docs#/Infringement%20Research/get_infringement_v1_infringement_research_predict_get">
              <img
                alt="swaggerui"
                src={SwaggerUiIcon}
                style={{
                  display: "inline-block",
                  width: "2rem",
                  margin: "0 3px",
                }}
              />
            </a>
          </p>
          <p>
            <strong>Summary:</strong> Send a text and patent number(s) for
            infringement detection.
          </p>
          <p>
            <strong>Description:</strong> Create a request item with the
            following information:
          </p>
          <ul>
            <li>
              <strong>
                <code>text</code>
              </strong>
              : could be the description of a company asset or a product
              explanation. It should be minimum length of <code>20</code> words
              for better performance. Note that GET queries are limited to{" "}
              <code>2048</code> characters (<em>required</em>).
            </li>
            <li>
              <strong>
                <code>patent_number</code>
              </strong>
              : should start with a country code and end with the <em>Kind</em>{" "}
              <em>Code</em>. You can enter as many as <code>5</code> patent
              numbers, each separated with a comma (<em>required</em>).
            </li>
            <li>
              <strong>
                <code>model_name</code>
              </strong>
              : is the name of the model empowering Stacks Patent Similarity
              engine. Defaults to <code>stk_nova</code>.
            </li>
          </ul>
          <h3 id="http-request">
            <a
              className="anchor"
              href="#http-request"
              aria-hidden="true"
              tabIndex="-1"
            >
              <svg
                className="octicon octicon-link"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z"
                ></path>
              </svg>
            </a>
            HTTP Request
          </h3>
          <p>
            <code>***GET*** /v1/infringement-research/predict</code>
          </p>
          <p>
            <strong>Parameters</strong>
          </p>

          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Located in</th>
                <th>Description</th>
                <th>Required</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>text</td>
                <td>query</td>
                <td></td>
                <td>Yes</td>
                <td></td>
              </tr>
              <tr>
                <td>patent_number</td>
                <td>query</td>
                <td></td>
                <td>Yes</td>
                <td></td>
              </tr>
              <tr>
                <td>model_name</td>
                <td>query</td>
                <td></td>
                <td>No</td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <p>
            <strong>Responses</strong>
          </p>

          <table>
            <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>200</td>
                <td>Successful Response</td>
              </tr>
              <tr>
                <td>422</td>
                <td>Validation Error</td>
              </tr>
            </tbody>
          </table>
          <h4 id="response-breakdown">
            <a
              className="anchor"
              href="#response-breakdown"
              aria-hidden="true"
              tabIndex="-1"
            >
              <svg
                className="octicon octicon-link"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z"
                ></path>
              </svg>
            </a>
            <strong>Response breakdown</strong>
          </h4>
          <p>
            The response contains a <code>data</code> key containing a list of
            dictionaries for patent numbers in
            <code>patent_number</code>, and an associated{" "}
            <code>predictions</code> key listing any relevant{" "}
            <code>claim_number</code>
            and <code>claim_text</code> based on the{" "}
            <em>technological similarity</em> between the claim and{" "}
            <code>text</code>. For each similar claim, the selected Stacks
            similarity model returns an infringement probability
            <code>infringement_prob</code> between 0 and 1, with 0 meaning{" "}
            <strong>completely different</strong> and{" "}
            <strong>no chance of infringement</strong>, whereas 1 means{" "}
            <strong>almost identical</strong> and{" "}
            <strong>completely infringing</strong>. In general,
          </p>
          <blockquote>
            <p>
              The higher the <code>infringement_prob</code>, the more similar{" "}
              <code>text</code> is to the associated <code>claim_text</code>. If
              nothing is returned for <code>predictions</code>, it means there
              is no infringement risk at all.
            </p>
          </blockquote>
          <h2 id="post">
            <a className="anchor" href="#post" aria-hidden="true" tabIndex="-1">
              <svg
                className="octicon octicon-link"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z"
                ></path>
              </svg>
            </a>
            <em>
              <strong>POST</strong>
            </em>
          </h2>
          <p>
            <a href="https://api.stacksai.com/v1/docs#/Infringement%20Research/post_infringement_v1_infringement_research_predict_post">
              <img
                alt="swaggerui"
                src={SwaggerUiIcon}
                style={{
                  display: "inline-block",
                  width: "2rem",
                  margin: "0 3px",
                }}
              />
            </a>
          </p>
          <p>
            <strong>Summary:</strong> Submit a text, list of claims or patent
            number(s) for infringement detection
          </p>
          <p>
            <strong>Description:</strong> Create a request item with the
            following information:
          </p>
          <ul>
            <li>
              <strong>
                <code>text</code>
              </strong>
              : should be minimum length of <code>20</code> words for better
              performance. The maximum limit is <code>512</code> words, so
              longer queries will be truncated. (<em>required</em>).
            </li>
            <li>
              <strong>
                <code>claims</code>
              </strong>
              : is a list of claims on which you're testing to see if the text
              is infringing. The maximum number of entries is <code>25</code> (
              <em>optional</em>).
            </li>
            <li>
              <strong>
                <code>patent_number</code>
              </strong>
              : has the same format and limits as in the GET request (
              <em>optional</em>).
            </li>
            <li>
              <strong>
                <code>model_name</code>
              </strong>
              : (<em>required</em>).
            </li>
          </ul>
          <h3 id="http-request-1">
            <a
              className="anchor"
              href="#http-request-1"
              aria-hidden="true"
              tabIndex="-1"
            >
              <svg
                className="octicon octicon-link"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z"
                ></path>
              </svg>
            </a>
            HTTP Request
          </h3>
          <p>
            <code>***POST*** /v1/infringement-research/predict</code>
          </p>
          <p>
            The POST method is only different in that there is an additional
            parameter <code>claims</code>, being a list of input claims, which
            must be included in the request body if <code>patent_number</code>{" "}
            is empty or not sent. The number of entries in <code>claims</code>{" "}
            cannot exceed <code>25</code>.
          </p>
          <p>
            <strong>Note:</strong> To avoid any possible errors, make sure that
            single and double quotes in claims are escaped.
          </p>
          <p>
            <strong>Responses</strong>
          </p>

          <table>
            <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>200</td>
                <td>Successful Response</td>
              </tr>
              <tr>
                <td>422</td>
                <td>Validation Error</td>
              </tr>
            </tbody>
          </table>
          <h2 id="v1101-eligibilitypredict">
            <a
              className="anchor"
              href="#v1101-eligibilitypredict"
              aria-hidden="true"
              tabIndex="-1"
            >
              <svg
                className="octicon octicon-link"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z"
                ></path>
              </svg>
            </a>
            /V1/101-ELIGIBILITY/PREDICT
          </h2>
          <h2 id="post-1">
            <a
              className="anchor"
              href="#post-1"
              aria-hidden="true"
              tabIndex="-1"
            >
              <svg
                className="octicon octicon-link"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z"
                ></path>
              </svg>
            </a>
            <em>
              <strong>POST</strong>
            </em>
          </h2>
          <p>
            <a href="https://api.stacksai.com/v1/docs#/101%20Eligibility%20Analyzer/eligibility_post_v1_101_eligibility_predict_post">
              <img
                alt="swaggerui"
                src={SwaggerUiIcon}
                style={{
                  display: "inline-block",
                  width: "2rem",
                  margin: "0 3px",
                }}
              />
            </a>
          </p>
          <p>
            <strong>Summary:</strong> Create and submit a request item for
            eligibility analysis
          </p>
          <p>
            <strong>Description:</strong> Create a request item with the
            following information:
          </p>
          <ul>
            <li>
              <strong>
                <code>claim</code>
              </strong>
              : should be minimum length of <code>20</code> words for better
              performance. The maximum limit is <code>512</code> words, so
              longer queries will be truncated. (<em>required</em>).
            </li>
            <li>
              <strong>
                <code>court_or_agency</code>
              </strong>
              : filters results based on a list of courts/agencies. The default
              value allows for top results from any US federal court (
              <em>optional</em>).
            </li>
            <li>
              <strong>
                <code>judges</code>
              </strong>
              : filters results based on a list of judges responsible for making
              decisions on the cases returned. The default value allows for top
              results decided by any US federal judge (<em>optional</em>).
            </li>
            <li>
              <strong>
                <code>from_date</code>
              </strong>
              : filters results starting from this date. Defaults to the
              decision date of the Alice case (<em>optional</em>).
            </li>
            <li>
              <strong>
                <code>to_date</code>
              </strong>
              : filters results until this date. Defaults to today's date (
              <em>optional</em>).
            </li>
            <li>
              <strong>
                <code>model_name</code>
              </strong>
              : is the name of the model for predicting (in)eligibility under
              step 1 and step 2 of the Alice test. Defaults to{" "}
              <code>stk_alora</code>.
            </li>
          </ul>
          <h3 id="http-request-2">
            <a
              className="anchor"
              href="#http-request-2"
              aria-hidden="true"
              tabIndex="-1"
            >
              <svg
                className="octicon octicon-link"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z"
                ></path>
              </svg>
            </a>
            HTTP Request
          </h3>
          <p>
            <code>***POST*** /v1/101-eligibility/predict</code>
          </p>
          <p>
            You can create a valid request body for this endpoint using a
            user-friendly form available upon clicking on the button below.
          </p>
          <p>
            <button
              className="button"
              onClick={() =>
                (window.location =
                  "https://api.stacksai.com/v1/101-eligibility/create_request_item")
              }
            >
              Create Request
            </button>
          </p>
          <p>
            You can use this form to type the claim in the provided text box.
            Furthermore, you can limit the analysis to specific courts, judges,
            or dates. The prior cases bound by these parameters will control the
            scope for making predictions on the patentability of the input
            claim.
          </p>
          <p>
            <strong>Note:</strong> Leaving any of the optional request
            parameters empty would allow the Stacks models to perform their
            predictive analytics using a wider set of prior cases. This means
            that a stricter research scope could lead to only a few or no prior
            cases, which would, in turn, limit the prediction significantly.
          </p>
          <p>
            <strong>Responses</strong>
          </p>

          <table>
            <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>200</td>
                <td>Successful Response</td>
              </tr>
              <tr>
                <td>422</td>
                <td>Validation Error</td>
              </tr>
            </tbody>
          </table>
          <h4 id="response-breakdown-1">
            <a
              className="anchor"
              href="#response-breakdown-1"
              aria-hidden="true"
              tabIndex="-1"
            >
              <svg
                className="octicon octicon-link"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z"
                ></path>
              </svg>
            </a>
            <strong>Response breakdown</strong>
          </h4>
          <p>
            The response item contains a <code>data</code> key containing a
            list, for the input <code>claim</code>, of the
            <code>predictions</code> with <code>alice_step_1</code> and{" "}
            <code>alice_step_2</code> keys, each containing a dictionary with a
            prediction key and a number from 0 to 1 called{" "}
            <code>confidence_level</code>. In general,
          </p>
          <blockquote>
            <p>
              The higher the <code>confidence_level</code>, the more confident
              the Stacks models are in their predictions.
            </p>
          </blockquote>
          <p>
            The duo is supported by another key called{" "}
            <code>similar_cases</code> that contains a list of key prior cases
            defining the basis for the returned predictions.
          </p>
          <p>
            Each similar prior case is a dictionary equipped with multiple keys
            such as <code>court_code</code>, <code>decision</code>,{" "}
            <code>decision_date</code>, <code>citation</code>,
            <code>patent_number</code>, <code>claim_number</code>,{" "}
            <code>judges</code>, <code>document_url</code>,{" "}
            <code>confidence_level</code> for technological similarity between
            claims in <code>claim_hierarchy</code> and the input{" "}
            <code>claim</code>, etc.
          </p>
          <p>
            <strong>Note:</strong> If a claim passes step 1 of the Alice test,
            there will be no prediction for the step 2.
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuickStartGuide;
