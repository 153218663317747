import React from "react";
import Layout from "../../layouts/Layout";
import Contact from "../home/Contact";
import GetStarted from "../getStarted/GetStarted";
import BrainstormSkillIcon from "../../assets/BrainstormSkill.png";
import MotherboardIcon from "../../assets/Motherboard.png";
import LawBookIcon from "../../assets/LawBook.png";

const Solutions = () => {
  return (
    <>
      <Layout>
        <div className="solutions-section">
          <div className="container-80">
            <h1 className="service-h1">101 Eligibility Analyzer</h1>

            <div data-aos="fade-up" className="feature">
              <img src={BrainstormSkillIcon} alt="Show Property" />
              <h4>
                A data-driven system that has combined all of the prior
                litigated claimed inventions under the Alice test in a
                systematic way, and applies the collective knowledge of these
                prior cases to your new claim or invention to predict its
                eligibility under the Alice test.
              </h4>
            </div>

            <div data-aos="fade-up" className="feature">
              <img src={MotherboardIcon} alt="Search Property" />
              <h4>
                If you are an IP owner, in-house counsel, investor, IP trader or
                any other stakeholder, use this service to evaluate your IPs
                under the Alice test at a fraction of cost, at scale, and with
                instant results.
              </h4>
            </div>

            <div data-aos="fade-up" className="feature">
              <img src={LawBookIcon} alt="Scales" />
              <h4>
                If you are a patent law practitioner, this service helps you get
                your research started with the latest updates of the law and
                allows you to structure your argument using relevant prior cases
                as support for its predictions.
              </h4>
            </div>
          </div>
          <GetStarted tryNowUrl={process.env.REACT_APP_101_URL}/>
        </div>
      </Layout>
      <Contact />
    </>
  );
};

export default Solutions;
